// responsive grid
$gutter: 16px;
$gutter-margin: #{$gutter / 2};
$quarter: calc(25% - #{$gutter});
$third: calc(33.3333333333% - #{$gutter});
$half: calc(50% - #{$gutter});

.homepage-destination {
  .content {
    max-width: 1200px;
    margin: 66px auto 0 auto;
    padding: 0 16px;

    h2 {
      margin: 0 0 0 4px;
      font-size: rem(26);
      font-weight: bold;
      color: $maroon-input-color;
    }

    .see-more {
      // padding: 0 4px;
      margin: 0 4px;
    }
  }

  .destination-items-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 9px auto 0 auto;
    width: auto;
    justify-content: space-between;
  }

  .destination-item {
    width: $quarter;
    display: flex;
    flex-direction: column;
    margin: $gutter-margin;
    padding: 0;
    box-sizing: border-box;

    h3 {
      margin: 0;
      font-weight: bold;
      font-size: rem(20);
    }

    span {
      font-size: rem(16);
    }

    .destination-price-points {
      span:first-child {
        text-decoration: line-through;
        margin-right: 8px;
      }
    }
  }
}

// certain Cards are hidden depending on the device width
@media screen and (max-width: 899px) {
  .homepage-destination .destination-item {
    width: $third;

    &.destination-mobile {
      display: none;
    }
  }
}

@media screen and (max-width: 590px) {
  .homepage-destination .destination-item {
    width: $half;

    &.destination-tablet {
      display: none;
    }
  }
}

@media screen and (max-width: 480px) {
  .homepage-destination .destination-item {
    width: 100%;
  }
}
