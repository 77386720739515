@media screen and (min-width: 1100px) {
  .homepage-steps {
    padding: 0 50px;
    position: relative;
    margin-top: 10%;
  }

  .homepage-steps > .content {
    max-width: 1200px;
    margin: 0 auto;
    height: 417px;
    position: relative;
  }

  .homepage-steps .text {
    right: 70px;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
  }

  .homepage-steps .card {
    position: relative;
    display: inline-block;
    margin: 0 5px;
    margin-top: 110px;
  }

  .homepage-hero .homepage-hero-outer-content {
    margin: 0 auto;
    min-height: 600px;
    height: 100%;
    position: relative;
    padding-top: 80px;
  }

  .homepage-partners {
    position: relative;
    padding: 0 50px;
  }

  .homepage-partners .content {
    margin: 0 auto;
    max-width: 1200px;
    height: 100%;
    position: relative;
  }

  .homepage-partners .partners {
    position: relative;
  }
}
