.loyalty-page-container {
  .loyalty-header,
  .no-programs {
    padding-left: 12px;
    padding-right: 12px;
  }

  .loyalty-header {
    align-items: baseline;
    min-height: 36px;
  }

  .no-programs {
    padding-top: 12px;
  }

  .award-wallet-section {
    margin-top: 24px;

    .award-wallet-subtext {
      margin-top: 12px;
    }
  }
}

// --- OLD below

.my-loyalty-programs {
  padding: 20px;
}
.my-loyalty-programs .button {
  display: inline-block;
  line-height: 42px;
  padding: 0 30px;
  border-radius: 42px;
  color: #fff;
  font-size: 15px;
  cursor: pointer;
  background-color: $treklee-dark-blue2;
  font-weight: normal;
}
.my-loyalty-programs .loyalty-program .type i {
  width: 40px;
  height: 40px;
  position: relative;
  display: inline-block;
}
.my-loyalty-programs .loyalty-program .type i svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 20px;
}
.my-loyalty-programs .loyalty-program .type i.airline svg {
  height: 23px;
}
.my-loyalty-programs .loyalty-program .type i.hotel svg {
  height: 25px;
}
.my-loyalty-programs .loyalty-program .type i.creditCard svg {
  height: 24px;
}
.my-loyalty-programs .loyalty-program {
  border-radius: 6px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px #dcdcdc;
  background-color: #ffffff;
  padding: 14px;
  padding-right: 60px;
  margin-bottom: 13px;
  position: relative;
}
.my-loyalty-programs .loyalty-program > b {
  width: 30px;
  height: 30px;
  position: relative;
  display: inline-block;
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -15px;
  color: #00bbff;
  cursor: pointer;
}
.my-loyalty-programs .loyalty-program > b > svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 16px;
}
.my-loyalty-programs .loyalty-program .program b {
  line-height: 28px;
  border-radius: 3px;
  display: inline-block;
  padding: 0 10px;
  font-size: 12px;
  color: #fff;
  font-weight: normal;
  margin: 6px 0;
}
.my-loyalty-programs .loyalty-program {
  display: flex;
}
.my-loyalty-programs .loyalty-program > div {
  width: 25%;
}
.my-loyalty-programs .loyalty-program > div > span {
  display: none;
}
.my-loyalty-programs .loyalty-program > div.status div {
  color: #706478;
  font-size: 12px;
  line-height: 40px;
}
.my-loyalty-programs .loyalty-program > div.points input {
  height: 42px;
  border-radius: 5px;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.26);
  border: solid 1px #dcdcdc;
  background-color: rgba(255, 255, 255, 0.36);
  box-sizing: border-box;
  line-height: 40px;
  padding: 0 10px;
  width: 100%;
  display: block;
  font-size: 15px;
  color: #b6aebc;
  outline: 0;
}
.my-loyalty-programs .loyalty-program > div.points input:focus {
  border: 1px solid #0194ff;
}
.my-loyalty-programs .loyalty-headers {
  display: block;
  padding-left: 14px;
  padding-right: 60px;
  display: block;
  font-weight: bold;
  font-size: 12px;
  color: #706478;
  text-transform: uppercase;
  line-height: 15px;
  margin-bottom: 10px;
}
.my-loyalty-programs .loyalty-headers:after {
  display: block;
  content: '';
  clear: both;
}
.my-loyalty-programs .loyalty-headers div {
  width: 25%;
  float: left;
}
.my-loyalty-programs .filters {
  color: #706478;
  font-size: 15px;
  // line-height: 20px;
  padding: 0 15px;
  margin: 30px 0 20px 0;
  span {
    display: inline-block;
    padding: 6px 0 7px 0;
  }

  .filters-select {
    color: #a59bab;
    margin: 0 0 0 10px;
  }
}
.my-loyalty-programs .filters .filters-select > div > div > div,
.my-loyalty-programs .filters .filters-select > div > div > div:focus {
  background-color: transparent;
  color: #a59bab;
  font-size: 15px;
  padding-top: 0;
  padding-bottom: 0;
  transform: translateY(1px);
}
@media screen and (max-width: 750px) {
  .my-loyalty-programs .loyalty-headers {
    display: none;
  }
  .my-loyalty-programs .loyalty-program {
    display: block;
    padding-right: 40px;
  }
  .my-loyalty-programs .loyalty-program:after {
    display: block;
    clear: both;
    content: '';
  }
  .my-loyalty-programs .loyalty-program > b {
    top: 50px;
    right: 5px;
  }
  .my-loyalty-programs .loyalty-program > div {
    width: 50%;
    float: left;
  }
  .my-loyalty-programs .loyalty-program > div.program {
    width: calc(100% - 50px);
  }
  .my-loyalty-programs .loyalty-program > div.type {
    width: 50px;
  }
  .my-loyalty-programs .loyalty-program > div > span {
    display: block;
    font-weight: bold;
    font-size: 12px;
    color: #706478;
    text-transform: uppercase;
    line-height: 15px;
    margin-bottom: 10px;
  }
}
