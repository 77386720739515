.homepage-steps .card {
  width: 180px;
  height: 207px;
  position: absolute;
  background-size: cover;
}

.homepage-steps .card span {
  color: $treklee-dark-blue2;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 2px;
  position: absolute;
  top: 25px;
  left: 30px;
  font-weight: bold;
}

.homepage-steps .card b {
  color: #000;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 2px;
  position: absolute;
  top: 45px;
  left: 30px;
  font-weight: bold;
}

.homepage-steps .text h2 {
  font-family: inherit;
  font-size: 34px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $maroon-input-color;
  padding: 0;
  margin: 40px 0 24px 0;
  position: relative;
  width: 100%;
  display: block;
  max-width: 335px;
}

.homepage-steps .text h2:after {
  display: block;
  content: '';
  position: absolute;
  top: -40px;
  left: 0;
  background-color: #00bbff;
  width: 50px;
  height: 2px;
}

.homepage-steps .text p {
  width: 100%;
  display: block;
  max-width: 335px;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.53;
  letter-spacing: normal;
  color: $maroon-input-color;
  margin: 0;
  padding: 0;
}

.homepage-steps .card.check-dates {
  background-image: url('/img/home/dates-box.svg');
}

.homepage-steps .card.transfer-points {
  background-image: url('/img/home/transfer-points.svg');
}

.homepage-steps .card.book-flights {
  background-image: url('/img/home/book-flights.svg');
}
