/* mobile responsive queries */
@media screen and (max-width: 1060px) {
  .content-container-main {
    padding: 10px 20px 0 20px;
  }
}
@media screen and (max-width: 960px) {
  .content-container-main {
    display: block;
    padding: 10px 20px 0 20px;
  }

  // .flight-search-main-inputs {
  //   display: initial;
  // }

  // div.flight-search-main-inputs {
  //   // width: 100%;
  // }

  .from-to-wrapper {
    width: 50%;
    margin-bottom: 0;
    & > div.ui.search.depart-input,
    & > div.ui.search.arrive-input {
      max-width: fit-content;
      width: 50%;
    }
  }

  .search-box-rewards-input {
    max-width: 745px;
  }

  .filter-search-container {
    width: 98%;
  }

  /* SEMANTIC */

  // .ui.selection.dropdown.dropdown-style
  div.flight-search-main-inputs.dropdown-style {
    // min-width: 24.7%;
    display: inline-block;
    width: 24%;
  }

  /* DATE PICKER */
}

@media screen and (max-width: 700px) {
  // body {
  //   font-size: 16px;
  // }
  /* Nav */
  #nav-main {
    padding: 0 12px;
  }

  .flight-search-main-inputs {
    // display: initial;
    // display: inline-block;
    display: block;
    width: 100%;
  }

  // .flight-search-main-inputs > div:nth-child(2) {
  //   margin: 0px 0px 8px 0px;
  // }

  // .search-box-rewards-input {
  //   // margin-top: 8px;
  // }

  .ui.selection.dropdown.dropdown-style {
    min-width: 50%;
  }

  .from-to-wrapper {
    margin-bottom: 8px;
    width: 100%;
  }

  /* DATE PICKER START */
  /* DATE PICKER END */

  .content-container-main {
    padding: 20px 8px 0 8px;
  }

  .filter-search-container {
    min-width: auto;
    width: 100%;
    margin: 0 1px;
    padding: 1px;
  }
}

@media screen and (max-width: 400px) {
  #flight-search-box {
    width: 100%;
  }
  .flight-options > div.ui.inline.dropdown > .text {
    font-size: 15px;
  }
}
