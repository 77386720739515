/* override react-dates */

// .Datepicker-wrapper {
// }
/* SingleDatePicker - specific */
.SingleDatePicker {
  width: 100%;
  .SingleDatePickerInput {
    width: 100%;
  }
  .DateInput {
    width: 96%;
  }
}
/* DateRangePicker - specific */
.DateRangePicker {
  width: 100%;
  .DateRangePickerInput {
    width: 100%;
  }
  .DateRangePickerInput_arrow {
    position: relative;
  }
  .DateInput_input {
    max-width: 139px;
  }
  span.separator {
    background-color: $separator-color;
    margin-top: 0;
    top: 2px;
    height: 40px;
  }
}

/* Common to both */
.Datepicker-wrapper {
  // take care of all border-radius
  .DateRangePickerInput,
  .DateRangePicker_picker,
  .SingleDatePickerInput,
  .SingleDatePicker_picker,
  .DateInput {
    border-radius: 5px;
  }
}
// take care of all heights
.DateRangePickerInput,
.SingleDatePickerInput,
.DateInput_input {
  height: 50px;
}
.SingleDatePicker_picker,
.DateRangePicker_picker {
  // outer container for opened calendar
  box-shadow: 0 0 2px 0 $cal-picker-shadow;
}
.SingleDatePickerInput,
.DateInput {
  // div
  width: auto;
}
.DateInput {
  padding: 0 3px;
}
.DateInput_input {
  // input
  @include borderBoxMixin;
  // color: #959595;
  // color: $maroon-input-color;
  // margin: 0 4px;
  // width: 94%;
  font-family: $main-font-family, $second-font-family;
  font-size: $main-search-form-font-size;
  font-weight: 500;
  line-height: 26px;
  /* input icon */
  padding-left: 36px;
  background-image: url('/img/calendar-icon.svg');
  background-repeat: no-repeat;
  background-size: 24px 26px;
  background-position: 5px 11px;
  // background-position: 10% 42%;
}
.DateInput_input__focused {
  border-bottom: 2px solid $cal-non-selected-date;
}

.CalendarDay__default {
  border: 2px solid #fff;
}
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: $cal-selected-date;
  color: #fff;
}
.CalendarDay__selected_span {
  background: $cal-non-selected-date;
  color: #fff;
}
.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: lighten($cal-non-selected-date, 35%);
  color: $cal-selected-date;
}
.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background: $cal-non-selected-date;
  color: #fff;
}

/* Mobile portal */
.SingleDatePicker_picker__portal,
.DateRangePicker_picker__portal {
  height: calc(100% - 68px);
}

// put month display below week header
.CalendarMonth_caption__verticalScrollable {
  padding-top: 0px;
}

.DayPicker__verticalScrollable .DayPicker_weekHeaders {
  top: 5px;
}

.DayPicker_weekHeader__verticalScrollable {
  // this allows days of week to always align with calculated width
  display: inline-flex;
  top: -24px;
}

.DayPicker {
  box-shadow: none;
}
.DayPicker_portal__vertical {
  margin-top: 72px;
}
.DayPickerNavigation__verticalDefault {
  display: flex;
  align-items: flex-end;
  top: -24px;
  // justify-content: left;
  // height: auto;
  // hide, but keep in DOM
  // height: 1px;
  // width: 1px;
}
.DayPickerNavigation_button__verticalDefault {
  // width: 38%;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.DayPickerNavigation_svg__vertical {
  height: 32px;
}
// .DateRangePicker_picker__fullScreenPortal
.DayPicker_transitionContainer__verticalScrollable {
  // calendar info height spacing
  padding-top: 38px;
}

// close icon
.DateRangePicker_closeButton,
.SingleDatePicker_closeButton {
  padding: 4px 0;
  left: 0;
  width: 32px;

  svg {
    font-size: 35px;
  }
}

.SingleDatePicker_closeButton_svg {
  // this is an annoying random wrapper only the the single picker.
  height: auto;
  width: auto;
}

.calendar-info {
  position: absolute;
  top: 0;
  .calendar-info-top,
  .calendar-info-bottom {
    position: fixed;
    height: auto;
    // width: 100px;
    z-index: 2;
  }
  .calendar-info-top {
    // margin-top: 6px;
    width: 100%;
    background-color: #fff;
    padding-bottom: 10px; // hide the overflow of month info during scroll
    button {
      padding: 8px;
      margin: 0 0 8px 8px;
    }
    .calendar-info-dates {
      display: flex;
      justify-content: center;
      padding: 0 4px 8px 4px;
      margin: 6px 0 0 38px;
      div {
        width: 50%;
        max-width: 180px;
        border: 1px solid $light-grey-border;
        border-radius: 5px;
        padding: 6px;
        margin-right: 8px;
        // &:first-child {
        // }
        &.focused {
          border: 1px solid $treklee-light-blue1;
        }
        &.placeholder {
          color: $placeholder-color;
        }
      }
    }
  }
  .calendar-info-bottom {
    width: 100%;
    padding: 0;
    // bottom: 32px;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    background-color: #fff;
    border-top: 1px solid $light-grey-border;
    box-shadow: 0 0 6px -4px $placeholder-color;
    button {
      margin: 8px;
      // margin-right: 8px;
      padding: 8px 26px;
      border-radius: 4px;
      width: 100%;
      // &:first-child {
      //   color: #333;
      //   margin-right: 60px;
      // }

      // &, <- uncomment to color both
      &:not([disabled]) {
        background-color: $treklee-dark-blue1;
      }
    }
  }
}

// react-dates inline media query
@media screen and (max-width: 960px) {
  .Datepicker-wrapper {
    .DateRangePicker .DateInput {
      // width: 42%;
      // width: 50%;
      width: auto;
      @include borderBoxMixin;
    }
  }
}

// treklee media query
@media screen and (max-width: 899px) {
  .Datepicker-wrapper {
    // both
    margin-bottom: 4px;
    .DateRangePickerInput,
    .SingleDatePickerInput {
      height: 40px;
      @include borderBoxMixin;
      .DateInput_input {
        height: 40px;
        max-width: none;
        line-height: 22px;
        // icon
        background-size: 19px 21px;
        background-position: 5px 9px;
      }
    }
    // just single
    .SingleDatePickerInput {
      width: 100%;
    }
    // just rangepicker
    .DateRangePicker {
      .DateInput {
        width: 50%;
      }
      span.separator {
        top: 2px;
        height: 34px;
      }
    }
  }
}
// @media screen and (max-width: 599px) {
// .DateRangePicker .DateRangePickerInput .DateInput_input {
// padding: 7px 11px 4px;
// padding-top: 7px;
// padding-bottom: 4px;
// icon
// background-size: 14px 14px;
// background-position: 6px 9px;
// }
// }
