$pointsColWidth: 170px;
$pointsColWidthSm: 110px;

.loyalty-points-input-wrapper {
  // .loyalty-table-icon-airline, hotel, creditCard
  [class^='loyalty-table-icon-'] {
    width: 26px;
    height: 26px;
    // font-size: 16px;
    display: inline-block;
  }
  .loyalty-table-icon-airline {
    transform: rotate(270deg);
  }
  .loyalty-points-table-container {
    max-height: 50vh;
  }
  thead th {
    background-color: initial;
    font-weight: bold;
  }
  .loyalty-status-select-cell {
    padding-right: 0px;
  }
  .loyalty-status-select {
    min-width: 100px;
  }

  .loyalty-points-table-head-points {
    min-width: $pointsColWidth;
  }
  .loyalty-points-table-footer-head-points {
    width: $pointsColWidth;
  }
}

@media screen and (max-width: 650px) {
  .loyalty-points-input-wrapper {
    .hide-tablet {
      display: none;
    }
    .loyalty-points-table-head-points {
      min-width: $pointsColWidthSm;
    }
    .loyalty-points-table-footer-head-points {
      width: initial;
      min-width: $pointsColWidthSm;
      max-width: $pointsColWidthSm;

      span {
        width: 138px;
        float: right;
      }
    }
  }
}

@media screen and (max-width: 560px) {
  .loyalty-points-input-wrapper {
    .loyalty-status-select {
      min-width: initial;
      width: 100px;
    }
  }
}

@media screen and (max-width: 450px) {
  .loyalty-points-input-wrapper {
    .hide-mobile {
      display: none;
    }
  }
}

@media screen and (max-width: 324px) {
  .loyalty-points-input-wrapper {
    .loyalty-points-table-footer-head-points {
      span {
        // ballpark estimate
        width: 80%;
      }
    }
  }
}
