.privacy-terms-container {
  h2 {
    font-size: 34px;
  }
  p {
    font-size: 17px;
  }

  header.bg-gradient {
    background-image: url('/img/home/homepage-top-bg.svg');
    background-position: 50% 20%;
    background-repeat: no-repeat;
    padding-top: 32px;
    h2,
    p {
      color: white;
      max-height: 300px;
      padding-left: 50px;
      padding-right: 50px;
      max-width: 600px;
    }
  }
  div.bg-wave-container {
    display: flex;
    height: 62px;
    div.bg-wave-short {
      height: 62px;
      width: 20%;
      margin-right: -1px;
      background-image: url('/img/home/homepage-wave-3.png');
      background-position: 0 124%;
      background-repeat: no-repeat;
      background-size: 100% 50%;
    }
    div.bg-wave-long {
      height: 62px;
      width: 80%;
      background-image: url('/img/home/homepage-wave-1.png');
      background-position: center top;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      transform: scaleX(-1);
    }
  }

  main.privacy-terms-main {
    display: flex;
    padding: 8px 50px;
    h2,
    h3,
    h4 {
      color: #44284f;
    }
    content.privacy-terms-content {
      margin-right: 26px;
    }
    .privacy-terms-subsection h3 {
      color: #706478;
    }
  }
  aside.privacy-terms-aside {
    padding-top: 12px;
  }

  @media screen and (max-width: 520px) {
    main.privacy-terms-main {
      display: block;
    }
  }
}
