.homepage-search {
  position: relative;

  .search-submit-button-wrapper {
    display: none;
    text-align: center;
  }

  .search-submit-button-wrapper,
  .search-submit-button-inline-wrapper {
    .search-submit-button,
    .search-submit-button-icon {
      background-color: $treklee-light-blue1;
      color: #fff;
    }
    .search-submit-button-icon {
      height: 50px;
      width: 50px;
      padding: 0;
      margin-left: 2px;
    }
    .search-submit-button {
      margin: 20px auto;
      border-radius: 50%;
      font-size: 15px;
      height: 70px;
      width: 70px;
    }
  }

  // .loyalty-field,
  .flight-options {
    padding: 0 15px;
    color: #fff;
    display: flex;
    flex-wrap: wrap;
  }
  // .loyalty-field {
  //   margin-top: 3px;
  // }
  & > .fields {
    padding: 3px 15px 0 15px;
    // padding-top: 3px;
    .left,
    .right {
      display: block;
      width: 50%;
      float: left;
      @include borderBoxMixin;
    }
    .left {
      padding-right: 5px;
      .ui.dropdown {
        line-height: 40px;
        border: 1px solid $light-grey-border;
        border-radius: 5px;
        color: $maroon-input-color;
        padding-left: 11px;
        @include borderBoxMixin;
        width: 100%;
        font-size: 13px;
        padding-right: 21px;
        i.dropdown {
          color: $light-grey-border;
          position: absolute;
          top: 0;
          right: 2px;
          line-height: 39px;
          font-size: 14px;
          padding-right: 7px;
        }
      }
    }
    .right {
      padding-left: 5px;
    }
    &:after {
      display: block;
      content: '';
      clear: both;
    }
  }
  & > .row {
    padding: 0 15px;
    margin-bottom: 3px;
    display: flex;
    .fields.calendar {
      width: 300px;
      padding-left: 3px;
    }
    .fields.location {
      flex: 1;
    }
  }

  .locations {
    line-height: 40px;
    color: $maroon-input-color;
    @include borderBoxMixin;
    width: 100%;
    & > .airport {
      width: calc(50% - 3px);
      @include borderBoxMixin;
      padding-left: 37px;
      padding-right: 10px;
      position: relative;
      display: inline-block;
      border-radius: 5px;
      background-color: #fff;
      &:first-child {
        width: calc(50% - 40px);
        border-radius: 5px 0 0 5px;
      }
    }
    .airport {
      .icon {
        position: absolute;
        top: 0;
        left: 0;
        width: 38px;
        height: 38px;
        color: $treklee-dark-blue2;
        svg {
          width: 24px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: block;
          fill: $treklee-dark-blue2;
        }
      }
    }

    & > .switch {
      display: inline-block;
      margin-right: 3px;
      @include borderBoxMixin;
      width: 40px;
      height: 40px;
      border-radius: 0 5px 5px 0;
      background-color: #fff;
      cursor: pointer;
      line-height: 40px;
      position: relative;
      color: $treklee-dark-blue2;
    }
    & > .switch svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 20px;
      transition: transform 0.3s;
    }
    & > .switch.right svg {
      transform: translate(-50%, -50%) rotate(180deg);
    }
    & > .switch.left svg {
      transform: translate(-50%, -50%) rotate(0deg);
    }
  }

  .airport-autocomplete {
    input {
      font-family: inherit;
      font-size: $main-search-form-font-size;
      color: $maroon-input-color;
      padding: 0;
      font-weight: 500;
      background-color: transparent;
      &::placeholder {
        color: #999;
      }
    }
  }

  .search-form-loyalty-programs {
    border: 0;
    margin-top: 16px;
    margin: 0;
    min-width: 0;
    padding: 12px 16px 0 0;
    position: absolute;
    right: 50px;
    vertical-align: baseline;

    .loyalty-input-btn {
      font-size: 16px;
      color: white;

      .loyalty-input-btn-text-tablet,
      .loyalty-input-btn-text-phone {
        display: none;
      }
    }
  }
}

.homepage-search .invalid .locations > .airport + .airport:after {
  background-color: $error4;
}

.homepage-search .passenger-count {
  &.passenger-count-mobile {
    display: none;
  }

  .passenger-count-user-icon {
    display: none;
  }
}
