.loyalty-selector {
  line-height: 28px;
  border-radius: 5px;
  color: $maroon-input-color;
  box-sizing: border-box;
  width: 100%;
  font-size: 13px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 40px;
  padding-right: 30px;
  position: relative;
  background-color: #fff;
  height: 40px;
  cursor: text;
}

.loyalty-selector > b {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  color: $treklee-dark-blue2;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    fill: $treklee-dark-blue2;
    margin-top: -2px;
  }
}

.loyalty-selector > div.placeholder {
  color: #999;
  line-height: 30px;
  font-size: 13px;
  letter-spacing: 0.6px;
  font-weight: normal;
  cursor: text;
}

.loyalty-selector > button {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 100%;
  cursor: pointer;
  background: none;
  border: none;

  svg {
    position: relative;
    width: 12px;
    color: $maroon-input-color;
    vertical-align: middle;
  }
}

// see .loyalty-chip
.loyalty-selector {
  & > .mobile-more,
  & > .desktop-more,
  & > .loyalty-chip {
    height: 26px;
  }
}

.loyalty-selector {
  & > .mobile-more,
  & > .desktop-more {
    @include borderBoxMixin;
    background-color: #787878;
    color: #fff;
    letter-spacing: 0.6px;
    border-radius: 5px;
    margin-right: 2px;
    padding: 0px 10px;
    display: inline-flex;
    align-items: center;
    justify-items: center;
    vertical-align: middle;
    justify-content: center;
  }
}

.loyalty-selector > .mobile-more {
  display: none;
}

@media screen and (min-width: 900px) {
  .loyalty-selector {
    line-height: 40px;
    padding-left: 47px;
    height: 52px;
  }
  .loyalty-selector > b {
    top: 5px;
    left: 3px;
  }
  .loyalty-selector > div.placeholder {
    line-height: 40px;
    font-size: 16px;
  }
  .loyalty-selector > b svg {
    width: 26px;
    height: 26px;
  }
  .loyalty-selector > i {
    top: 5px;
  }
  .loyalty-selector {
    & > .desktop-more,
    & > .loyalty-chip {
      height: 32px;
    }
  }
}

@media screen and (max-width: 599px) {
  .loyalty-selector > .mobile-more {
    display: inline-flex;
  }

  .loyalty-selector > .desktop-more,
  .loyalty-selector > .mobile-more + .loyalty-chip,
  .loyalty-selector > .mobile-more + .loyalty-chip + .loyalty-chip {
    display: none;
  }
}
