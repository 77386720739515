// this is a file for dialog styling. if it get's too big, split it up

#error-dialog-title {
  padding: 12px 24px 2px;
  svg {
    vertical-align: text-top;
    position: relative;
    top: 1px;
    margin-right: 6px;
    color: $error2;
  }
}

.create-account-dialog {
  .create-account-dialog-content {
    padding: 4px 24px;
  }

  .create-account-dialog-btn-secondary {
    max-width: 260px;
    margin: 0 auto;
  }

  .create-account-dialog-signup-btn {
    background-color: $treklee-dark-blue1;

    &:hover {
      background-color: $treklee-dark-blue2;
    }
  }

  .create-account-dialog-login-btn {
    color: $treklee-light-blue1;
  }

  .create-account-dialog-cancel-btn {
    color: $light-grey-text2;
  }
}
