@media screen and (max-width: 500px) {
  .homepage-hero {
    .homepage-text-headings {
      h1 {
        font-size: rem(28);
      }
    }
    .homepage-hero-outer-content {
      padding-top: 20px;
    }
  }

  .homepage-text div.section.section-3 img.screen {
    left: 60px;
  }
  .homepage-text div.section.section-3 img.person {
    left: 30px;
  }

  .homepage-partners .partners > div {
    width: 50%;
  }
  .homepage-partners .partners a {
    display: inline-block;
    float: left;
  }

  .homepage-partners .partners:after {
    display: block;
    content: '';
    clear: both;
  }
}
