.goals-trips {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}
@media screen and (max-width: 649px) {
  .goals-trips {
    max-width: 500px;
  }
}
.goals-trips .goals .item {
  padding: 15px 0;
  box-sizing: border-box;
}

@media screen and (min-width: 650px) {
  .goals-trips .goals {
    margin-left: -15px;
    margin-right: -15px;
  }
  .goals-trips .goals .item {
    width: 50%;
    padding: 15px;
    float: left;
    box-sizing: border-box;
  }
  .goals-trips .goals:after {
    display: block;
    content: '';
    clear: both;
  }
}
@media screen and (min-width: 1100px) {
  .goals-trips .goals {
    margin-left: -15px;
    margin-right: -15px;
  }
  .goals-trips .goals .item {
    width: 33%;
    padding: 15px;
    float: left;
    box-sizing: border-box;
  }
  .goals-trips .goals:after {
    display: block;
    content: '';
    clear: both;
  }
}

.goals-trips .goal {
  max-width: 550px;
  background-color: #ffffff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
}
.goals-trips .goal .image {
  border-radius: 6px 6px 0 0;
  padding-top: 55%;
  position: relative;
  background-color: #dcdcdc;
  background-size: cover;
}
.goals-trips .goal .image .summary {
  background-color: rgba(255, 255, 255, 0.8);
  color: #706478;
  position: absolute;
  top: 14px;
  left: 14px;
  right: 14px;
  border-radius: 6px;
  min-height: 70px;
  box-sizing: border-box;
  padding: 10px 15px;
  padding-right: 100px;
}
.goals-trips .goal .image .summary h2 {
  font-size: 25px;
  margin: 0;
  padding: 0;
  font-weight: normal;
}
.goals-trips .goal .image .summary > span {
  display: block;
  line-height: 19px;
  font-size: 13px;
}
.goals-trips .goal .image .summary i {
  position: absolute;
  top: 15px;
  right: 15px;
  height: 40px;
  min-height: 16px;
  line-height: 16px;
  border-left: 1px solid #706478;
  font-style: normal;
  width: 60px;
  padding-left: 20px;
}
.goals-trips .goal .image .summary i u {
  display: block;
  text-decoration: none;
  font-size: 14px;
}
.goals-trips .goal .image .summary i u b {
  width: 16px;
  height: 16px;
  display: inline-block;
  position: relative;
  margin-left: 5px;
}
.goals-trips .goal .image .summary i u b svg {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 16px;
  transform: translate(-50%, -50%);
  margin-top: -2px;
}
.goals-trips .goal .image .summary i u b:before {
  content: '.';
  opacity: 0;
}
.goals-trips .goal .image .summary i u + u {
  padding-top: 8px;
}
.goals-trips .goal .programs {
  border-radius: 0 0 6px 6px;
  border: solid 1px #dcdcdc;
  padding: 15px;
}
.goals-trips .goal .programs .program > i {
  line-height: 20px;
  color: #706478;
  font-size: 15px;
  font-style: normal;
}
.goals-trips .goal .programs .program > b {
  height: 5px;
  border-radius: 5px;
  background-color: #dcdcdc;
  margin: 10px 0 15px 0;
  display: block;
  overflow: hidden;
}
.goals-trips .goal .programs .program > b > u {
  text-decoration: none;
  display: block;
  height: 5px;
  background-color: #00bbff;
}
.goals-trips .goal .programs > span {
  color: $treklee-dark-blue2;
  font-size: 12px;
  line-height: 16px;
}
