$photoSize: 128px;

// AccountPage component

.card-layout {
  margin-bottom: 8px;
  width: calc(33.33% - 8px);
}

.card-action-layout {
  // margin-bottom: 8px;
  height: 100%;
}

.card-children {
  padding: 12px;
  height: 100%;
}

// common to img and span
.user-profile-image {
  @include borderBoxMixin;
  width: $photoSize;
  height: $photoSize;
  border-radius: 50%;
}

// img.user-profile-image {
//   border: 1px solid #545454;
// }

span.user-profile-image {
  border: 1px solid #545454;
  font-size: 38px;
  align-items: center;
  background-color: white;
  color: #2c2c2c;
  display: inline-flex;
  justify-content: center;
  line-height: 1;
  vertical-align: middle;
}

.account-page-container {
  // UserInfo

  .account-user-info-container {
    position: relative;
  }

  .user-profile-image-container {
    width: $photoSize;
    position: absolute;
    right: 20px;
    top: 20px;
  }

  // .welcome-message {
  //   margin: 0 0 24px 0;
  // }

  .fields {
    margin-top: 16px;
    text-align: left;

    // match padding with MUI inputs
    .field-name,
    .no-edit {
      padding: 6px 0 7px;
    }

    .fields-row {
      display: flex;
      align-items: center;
      padding: 4px 0;
    }

    .field-name {
      width: 132px;
    }

    .field-value {
      flex: 1;
    }

    .no-edit {
      overflow-wrap: break-word;
      overflow: auto;
    }
  }

  .home-airport {
    .field-value {
      display: flex;
    }
    // see .input-with-edit-icon
    .airport-autocomplete .input-with-edit-icon input {
      font-size: 16px;
      padding-left: 0;
      padding-right: 0;
      height: 37px;
      animation-name: mui-auto-fill-cancel;
      box-sizing: content-box;
      -webkit-tap-highlight-color: transparent;
    }
  }

  // Dashboard

  .account-dashboard {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    height: 131px;

    .dashboard-icon {
      position: absolute;
      right: 16px;
      bottom: 24px;
      color: $treklee-med-blue1;
      font-size: rem(38);
    }

    .account-dashboard-loyalty-link-btn {
      color: $treklee-light-blue1;
      margin-left: -8px;
    }
  }

  // RecentSearches

  // .recent-searches-paper {
  //   padding: 0;
  // }

  .account-recent-searches-container {
    h2 {
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  .recent-searches-scrolly {
    max-height: 45vh;
    overflow-y: auto; // this should scroll on overflow
  }

  .recent-search-row-link {
    border-bottom: 1px solid #eaeaea;
    border-radius: 5px 5px 0 0;
    display: block;
    padding: 2px 12px;
    text-align: left;
    width: 100%;

    &:hover {
      background-color: rgba(63, 81, 181, 0.04);
    }
  }

  .recent-search-row {
    align-items: center;
  }

  .btn-recent-search-icon {
    border-radius: 50%;
    color: $treklee-dark-blue1;
    min-width: initial;
  }

  .recent-searches-show-all {
    margin: 4px 8px -8px 8px;
  }

  .airports {
    display: inline-block;
    min-width: 280px;
    width: 35%;
  }

  .airport-mobile {
    display: none;
  }

  // ChangePassword

  .account-settings-container {
    max-width: 400px;

    h2 {
      margin-bottom: 0;
    }
  }
}

.password-change-message {
  margin: -8px 0 8px 0;
  font-size: 16px;
  text-align: center;
}

@media screen and (max-width: 720px) {
  .account-page-container {
    .user-profile-image-container {
      margin: 0 auto;
      position: static;
    }

    .welcome-message {
      margin-top: 24px;
      text-align: center;
    }

    .account-dashboard {
      display: none;
    }

    .airports {
      min-width: 0;
    }

    .airport-mobile {
      display: initial;
    }

    .airport-desktop {
      display: none;
    }
  }
}

@media screen and (max-width: 425px) {
  .account-page-container .fields .field-name {
    width: 110px;
  }
}
