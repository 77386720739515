@media screen and (max-width: 899px) {
  // HOMEPAGE HERO
  .homepage-hero.search-page.summaryShowed {
    display: block;
  }
  .homepage-hero.search-page.summaryShowed.fixed {
    position: fixed;
    top: 0px;
    left: 0;
    right: 0;
  }
  .search-space.fixed {
    height: 60px;
    display: block;
  }
  .homepage-hero.search-page .search-summary {
    display: block;
  }
  .homepage-hero.search-page .form.homepage-search > button {
    margin-bottom: 10px;
  }
  .homepage-hero.search-page .form.homepage-search.summary-shown {
    display: none;
  }

  // HOMEPAGE SEARCH
  .homepage-search {
    & > .row {
      display: block;
      margin-bottom: -1px;
      padding: 0 15px;
      .fields.calendar {
        padding-top: 3px;
        width: 100%;
        padding-left: 0;
      }
      .fields.location {
        width: 100%;
      }
    }

    .search-form-loyalty-programs {
      right: 0;
    }

    .search-submit-button-wrapper {
      display: block;
    }
    .search-submit-button-inline-wrapper {
      display: none;
    }
  }

  // HOMEPAGE BOXES
  .homepage-boxes {
    padding: 20px 10px 50px 10px;
  }
  .homepage-boxes > .section {
    display: block;
    text-align: center;
  }
  .homepage-boxes > .section > div {
    width: auto;
    max-width: 300px;
    display: inline-block;
    margin-top: 30px;
    padding: 0 10px;
  }
  .homepage-boxes > .section > div:nth-child(1),
  .homepage-boxes > .section > div:nth-child(2),
  .homepage-boxes > .section > div:nth-child(3) {
    padding: 0 10px;
  }

  // HOMEPAGE TEXT
  .homepage-text01 {
    top: 0;

    & > img.bg {
      position: absolute;
      top: 500px;
      transform: translateY(-50%);
      left: 0;
      width: 40%;
      max-width: 540px;
      min-width: 360px;
    }
    & > .section-1 {
      margin: 0% auto 20% auto;
    }
  }
}
