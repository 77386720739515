// MUI Snackbar - child of body
.MuiSnackbar-root {
  z-index: 10000000;

  &.searchform-validation-error {
    top: 8px;
    min-width: 200px;

    .MuiSnackbarContent-root {
      background-color: $error3;
    }

    .MuiSnackbarContent-message {
      margin: 0 auto;
    }
  }
}
