/*
NAV BAR
*/

#container-nav {
  position: relative; // for box-shadow to appear
  box-shadow: 0px -6px 20px 3px #615d5d;
}

.ui.text.menu {
  & a.item.nav-link,
  & a.item.nav-link-mobile {
    margin: 0 0 0 20px;
    text-transform: uppercase;
  }
}

#nav-main {
  display: flex;
  align-items: inherit;
  flex-direction: inherit;
  width: 100%;
  max-width: 100%;
  padding: 0 30px;
  height: 70px;
}

.logo-container {
  margin-right: 1.5em;
  padding: 0;
  // height: 82px;
  img {
    max-width: initial;
    width: 140px;
  }
}

// Account Nav related

// overwrite MUI
header.my-account-appbar {
  box-shadow: none;
  background-color: $treklee-dark-blue2;
}

.my-account-appbar {
  .my-account-toolbar {
    padding-bottom: 0;
    align-items: flex-end;
    max-width: $max-page-width;
    width: 100%;
    height: 68px;
    margin: 0 auto;

    &.centered {
      justify-content: space-evenly;

      .my-account-link {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  .my-account-link {
    margin-left: 2.5%;
    margin-right: 2.5%;
    padding: 8px 8px 1px 8px;
    font-size: 14px;
    color: darken(#eee, 18%);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    min-width: 62px;

    .my-account-nav-icon {
      font-size: rem(28);
      margin-bottom: 6px;
    }

    .my-account-underline {
      opacity: 0;
      background-color: #eee;
      height: 2px;
      width: 100%;
      margin-top: 6px;
    }

    &.active {
      color: #eee;

      .my-account-underline {
        opacity: 1;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .my-account-appbar {
    .my-account-toolbar {
      height: 76px;
    }
  }
}
