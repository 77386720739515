.paper-layout {
  padding: 12px;
  margin-bottom: 8px;

  &.padding-lr0 {
    padding-left: 0;
    padding-right: 0;
  }
}

.input-with-edit-icon {
  button {
    // height: 20px;
    // width: 20px;
    padding: 6px;

    svg {
      font-size: rem(20);
    }
  }

  .done-icon svg {
    fill: $light-grey-text2;
    font-size: rem(22);
  }

  .validation-error {
    color: $error1;
    margin-top: 4px;
    font-size: 0.75rem;
  }
}

button.btn-dark-blue {
  background-color: $treklee-dark-blue2;
  &:hover {
    background-color: darken($treklee-dark-blue2, 5%);
  }
}
