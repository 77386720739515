.text-center {
  text-align: center !important;
}

.text-bold {
  font-weight: bold !important;
}

.text-muted {
  color: #808080 !important;
}

.text-active {
  color: $treklee-dark-blue1 !important;
}

.img-center {
  display: block;
  margin: auto;
}

.margin-reset {
  margin: 0 !important;
}

.padding-reset {
  padding: 0 !important;
}

.margin-center {
  margin: 0 auto;
}

.layout-max-width-centered {
  max-width: $max-page-width;
  margin: 0 auto;
}

.flex1 {
  flex: 1;
}

.flex-container-sb {
  display: flex;
  justify-content: space-between;
}

// Flexbox container for vertical / horizontal centering
.Aligner {
  display: flex;
  align-items: center;
  justify-content: center;
}

// Flexbox child for vertical / horizontal centering
.Aligner-item {
  max-width: 50%;
}

// align top left
.Aligner-item--top {
  align-self: flex-start;
}

// align bottom right
.Aligner-item--bottom {
  align-self: flex-end;
}
