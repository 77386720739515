$dialog-desktop: 'button.dialog-title-close-btn';
$dialog-mobile: 'button.dialog-title-close-btn-mobile';

// Use a Sass Variable for a Selector | CSS-Tricks
// https://css-tricks.com/snippets/sass/use-sass-variable-selector/)
#{$dialog-desktop},
#{$dialog-mobile} {
  position: absolute;
  z-index: 1;
}

#{$dialog-desktop} {
  top: 8px;
  right: 8px;
}

#{$dialog-mobile} {
  top: -1px;
  left: 0;
  display: none;

  svg {
    font-size: 35px;
  }
}

@media screen and (max-width: 576px) {
  #{$dialog-desktop} {
    display: none;
  }
  #{$dialog-mobile} {
    display: initial;
  }
}
