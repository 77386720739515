.homepage-partners:after {
  display: block;
  content: '';
  clear: both;
}

.homepage-partners .partners {
  text-align: center;
}

.homepage-partners h4 {
  color: #ccc;
  font-size: 18px;
  position: relative;
  margin: 40px 0 20px 0;
  padding: 0;
  text-align: center;
}

.homepage-partners .partners a {
  display: inline-block;
  font-size: 13px;
  color: $treklee-dark-blue2;
  text-decoration: none;
  line-height: 30px;
  position: absolute;
  /* bottom: 20px; */
  /* right: 20px; */
  box-sizing: border-box;
  bottom: -30px;
  right: 0;
}
.homepage-partners .partners > div {
  width: 20%;
  display: inline-block;
  text-align: center;
  margin: 10px 0;
}
.homepage-partners .partners > div img {
  max-width: 100%;
  max-height: 30px;
}

.homepage-partners .partners a:hover {
  color: #00bbff;
}
