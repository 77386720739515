@media screen and (max-width: 699px) {
  .homepage-search .search-form-loyalty-programs {
    .loyalty-input-btn {
      .loyalty-input-btn-text-desktop,
      .loyalty-input-btn-text-phone {
        display: none;
      }
      .loyalty-input-btn-text-tablet {
        display: initial;
      }
    }
  }

  .homepage-text01 {
    & > img.bg {
      position: absolute;
      top: 500px;
      transform: translateY(-50%);
      left: 0;
      width: 60%;
      max-width: 540px;
    }
    & > .section-1 {
      margin: 0% auto 20% auto;
    }
  }

  .homepage-text .section .text {
    max-width: calc(100% - 40px);
  }

  .homepage-text .section-1 {
    width: 100%;
    overflow: hidden;
  }

  .homepage-text .section-1 .text {
    display: block;
    width: 335px;
    margin: 0 auto;
    padding: 50px 0 30px 0;
    text-align: center;
    margin-top: 50px;
  }
  .homepage-text div.section.section-1 img.great {
    position: absolute;
    bottom: 130px;
    right: 100px;
    width: 110px;
  }
  .homepage-text div.section.section-1 img.poor {
    position: absolute;
    bottom: 260px;
    right: 200px;
    width: 100px;
  }
  .homepage-text div.section.section-1 div.airplane {
    width: 300px;
    display: block;
    margin: 0 auto;
    padding-bottom: 30px;
    position: relative;
    margin-top: 50px;
  }
  .homepage-text div.section.section-1 img.airplane {
    width: 300px;
    display: block;
    margin: 0 auto;
  }

  .homepage-text h2:after {
    left: 50%;
    margin-left: -25px;
  }

  .homepage-text div.section.section-3 {
    position: relative;
    padding: 0;
    margin-top: 40px;
  }

  .homepage-text .section-3 .text {
    padding-left: 20px;
    padding-bottom: 50px;
    text-align: center;
  }
  .homepage-text div.section.section-3 p {
    margin: 0 auto;
  }
  .homepage-text div.section.section-3 .images {
    margin-top: 200px;
  }
  .homepage-text div.section.section-3 img.bg {
    margin-left: auto;
    width: 60%;
    max-width: 540px;
    min-width: 300px;
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .homepage-text .section-3 h2:after {
    left: 50%;
    margin-left: -25px;
  }
  .homepage-text div.section.section-3 img.screen {
    width: 190px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    right: 40%;
  }

  .homepage-text div.section.section-3 img.person {
    width: 65px;
    margin-top: -30px;
    margin-right: 170px;
    position: absolute;
    top: 50%;
    display: block;
    right: 40%;
  }

  .homepage-steps {
    text-align: center;
    padding-bottom: 50px;
    margin-top: -200px;
  }

  .homepage-steps .text {
    margin: 0 auto;
    max-width: 335px;
    padding-top: 70px;
    padding-bottom: 30px;
  }

  .homepage-steps .text h2:after {
    display: block;
    content: '';
    position: absolute;
    top: -40px;
    left: 50%;
    margin-left: -25px;
    background-color: #00bbff;
    width: 50px;
    height: 2px;
  }

  .homepage-steps .card {
    position: relative;
    display: inline-block;
    margin: 0 10px;
  }

  .homepage-steps .text p {
    padding: 0 20px;
    box-sizing: border-box;
  }
  .homepage-text h2 {
    margin: 0 auto;
  }

  .homepage-footer .logo + ul {
    margin-left: 0;
    margin-top: -70px;
  }

  .homepage-footer ul {
    width: 100%;
  }

  .homepage-footer ul {
    margin-bottom: 50px;
  }

  .homepage-hero {
    background: $treklee-dark-blue1;

    .homepage-large-text-header {
      font-size: 28px;
    }
    .homepage-text-headings {
      h1 {
        font-size: rem(40);
      }
      h2 {
        display: none;
      }
    }
    .homepage-hero-inner-div {
      background-color: transparent;
    }
    .homepage-hero-outer-content {
      // margin: 0 auto;
      // max-width: 1200px;
      // min-height: auto;
      // height: 100%;
      // position: relative;
      // padding-top: 80px;
      padding-bottom: 50px;
    }
  }

  .homepage-partners {
    position: relative;
    padding: 0 20px;
  }

  .homepage-partners .content {
    margin: 0 auto;
    max-width: 1200px;
    position: relative;
    padding-bottom: 50px;
  }

  .homepage-partners .partners {
    position: relative;
  }

  .homepage-signup h1 {
    font-size: 28px;
  }
}
