.homepage-signup {
  background: $treklee-med-blue1;
  background-image: linear-gradient(
    $treklee-med-blue1 0%,
    $treklee-dark-blue1 100%
  );
  color: #fff;
  padding: 50px;
}

.homepage-signup a {
  line-height: 42px;
  max-width: 253px;
  color: #064fff;
  background-color: #fff;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.13);
  border: 0;
  outline: 0;
  text-decoration: none;
  display: block;
  margin: 20px auto 0px auto;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 2px;
  text-align: center;
  border-radius: 42px;
}
