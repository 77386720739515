@media screen and (max-width: 620px) and (min-width: 600px) {
  .homepage-search .search-form-loyalty-programs {
    .loyalty-input-btn {
      .loyalty-input-btn-text-desktop,
      .loyalty-input-btn-text-tablet {
        display: none;
      }
      .loyalty-input-btn-text-phone {
        display: initial;
      }
    }
  }
}
