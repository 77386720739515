@media screen and (max-width: 599px) {
  .homepage-hero.search-page.summaryShowed {
    top: 56px;
  }

  .homepage-large-text-header {
    font-size: 24px;
  }
  .flight-options .flight-options-select div {
    font-size: 15px;
  }

  // HOMEPAGE SEARCH
  .homepage-search {
    position: relative;
  }

  .homepage-search .passenger-count {
    display: none;
  }

  .homepage-search .passenger-count.passenger-count-mobile {
    display: block;
    position: relative;
    top: -1px;
    background-color: #fff;
    line-height: 40px;
    color: $maroon-input-color;
    font-size: 14px;
    margin: 0;
    border-radius: 5px;
    padding-left: 40px;

    .passenger-count-button-container {
      .passenger-count-user-icon {
        color: $treklee-dark-blue2;
        position: absolute;
        top: 0;
        left: 0;
        width: 40px;
        height: 40px;
        display: block;
        svg {
          position: absolute;
          height: 20px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .passenger-count-button {
        position: initial;
        display: block;
        line-height: 40px;
        .passenger-count-button-dropdown-icon {
          top: 8px;
          right: 3px;
          svg {
            fill: $maroon-input-color;
          }
        }
      }
    }
  }

  .homepage-search .locations {
    position: relative;
  }
  .homepage-search .locations > .airport:first-child {
    width: calc(100% - 40px);
  }
  .homepage-search .locations > .switch {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    width: 40px;
    height: 40px;
  }
  .homepage-search .locations > .airport ~ .airport {
    margin-top: 3px;
    width: 100%;
  }
  .homepage-search .locations {
    line-height: 36px;
  }

  .homepage-search .trip-types {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  // .homepage-search .loyalty-field {
  //   margin-top: 1px;
  // }
  .homepage-search {
    .search-form-loyalty-programs {
      padding-right: 4px;
      padding-top: 10px;

      // .loyalty-input-btn {}
    }
  }
}
