@media screen and (min-width: 600px) {
  .mobile {
    display: none;
  }
}

@media screen and (max-width: 599px) {
  .desktop {
    display: none;
  }
}

/*
SEARCH BOX
*/

.search-box-wrapper {
  min-height: 160px;
  background: linear-gradient($blue-top-searchbox, $treklee-dark-blue2);
  color: white;
  padding: 40px 15px 40px 15px;
}

// .search-box-container {
//   margin-bottom: 30px;
// }

.flight-search-box {
  width: auto;
}

/** Drawer icon */
.flight-search-box .mui-select-menu svg {
  fill: white;
}

.sort-select > div > div > div {
  font-size: 13px;
}
.flight-options {
  & > .text {
    font-weight: $flight-search-font-weight;
  }

  .flight-options-select {
    vertical-align: baseline;

    svg {
      fill: white;
    }

    div {
      color: white;
    }

    &:first-child {
      margin-right: 10px;
    }

    &:nth-child(2) {
      width: 170px;
    }

    &.flight-options-select-cabin {
      width: initial;
    }
  }
}

.flight-search-main-inputs {
  margin: 8px 0;
  display: flex;
  align-items: flex-start;
}

.from-to-wrapper {
  display: flex;
  margin-right: 4px;
  height: 38px;
  width: auto;
  // max-width: 148*2px;
  // background: linear-gradient($blue-top-search-input, $blue-bottom-search-input);
  background-color: white;
  border-radius: 4px;

  & > div.ui.search {
    &.depart-input,
    &.arrive-input {
      border: none;
      max-width: 158px;

      & > div.ui.input {
        width: 100%;

        & > input {
          padding: 0.55em 0.8em;
        }
      }

      & > div.ui.icon.input > input {
        // background: linear-gradient($blue-top-search-input, $blue-bottom-search-input);
        // color: white;
        // max-width: 158px;//not needed?
        width: 100%;
        color: $maroon-input-color;
        background-color: transparent;
        border-radius: $match-border-radius;
        border: none;

        &::placeholder {
          // color: white;
          color: $maroon-input-color;
          font-weight: 500;
        }
      }
    }
  }

  & > .from-to-icon {
    display: inline-block;
    position: relative;
    top: 10px;
    left: 8px;
    // font-size: 14px; // see JSS
    color: $treklee-dark-blue2;
  }
}

.ui.selection.dropdown.dropdown-style {
  // background: linear-gradient($blue-top-search-input, $blue-bottom-search-input);
  background-color: white;

  & i.dropdown.icon {
    // color: $blue-bottom-search-input;/* Hide icon */
    color: white;
    /* Hide icon */
  }
}

.search-box-rewards-input {
  width: 100%;
  margin-bottom: 30px;

  & > div.default.text {
    color: $maroon-input-color;
  }
}

/* for first separator only */
div.separator-container {
  width: 0;
}

/* span separator */
span.separator {
  display: inline-block;
  position: relative;
  margin-top: 4px;
  height: 29px;
  width: 1px;
  // vertical-align: middle;
  background-color: $maroon-input-color;
  z-index: 1;
}

/* BUTTON */
.button-flight-search-wrapper {
  text-align: center;
  margin: 0 auto;
  position: relative;
  top: -20.3px;
  /*button height 40.6px*/
}

.button-flight-search {
  width: 260px;
  border-radius: 20px;
  background-color: $treklee-light-blue1 !important;
  color: white;
  line-height: 40px;
  outline: 0;
  border: 0;
  cursor: pointer;
  &:hover {
    background-color: darken($treklee-light-blue1, 4%) !important;
  }
}

// #container-nav-main {}
.content-container-main {
  display: flex;
  padding: 10px 60px 0 60px;
}

.filter-search-container {
  min-width: 300px;
  margin: 0 15px;

  // padding: 10px;
  .filter-search {
    border: 1px #e2e2e2 solid;

    i.dropdown.icon {
      color: black;
    }
  }
}

.search-results {
  width: 100%;
}
