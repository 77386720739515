@media screen and (max-width: 899px) and (min-width: 700px) {
  .homepage-text div.section {
    padding: 0;
    position: relative;
  }

  .homepage-text .section-1 {
    width: 100%;
    overflow: hidden;
  }

  .homepage-text .section-1 .text {
    display: block;
    width: 335px;
    margin: 0 auto;
    padding: 50px 0 30px 0;
    text-align: center;
  }
  .homepage-text h2:after {
    left: 50%;
    margin-left: -25px;
  }
  .homepage-text div.section.section-1 img.great {
    position: absolute;
    bottom: 130px;
    right: 100px;
    width: 110px;
  }
  .homepage-text div.section.section-1 img.poor {
    position: absolute;
    bottom: 260px;
    right: 200px;
    width: 100px;
  }
  .homepage-text div.section.section-1 div.airplane {
    width: 300px;
    display: block;
    margin: 0 auto;
    padding-bottom: 30px;
    position: relative;
    margin-top: 50px;
  }
  .homepage-text div.section.section-1 img.airplane {
    width: 300px;
    display: block;
    margin: 0 auto;
  }

  .homepage-text .right-image {
    position: relative;
    width: 63%;
    margin-left: auto;
    display: block;
  }

  .homepage-text div.section.section-3 {
    position: relative;
    padding: 0;
  }

  .homepage-text .section-3 .text {
    padding-left: 20px;
    padding-bottom: 50px;
    text-align: center;
  }
  // .homepage-text div.section.section-3 .images {
  // }
  .homepage-text div.section.section-3 h2,
  .homepage-text div.section.section-3 p {
    margin: 0 auto;
  }

  .homepage-text div.section.section-3 .images {
    margin-top: 230px;
    margin-bottom: -50px;
  }
  .homepage-text div.section.section-3 img.bg {
    margin-left: auto;
    width: 45%;
    max-width: 540px;
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .homepage-text div.section.section-3 img.screen {
    width: 250px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    right: 33.48%;
  }

  .homepage-text div.section.section-3 img.person {
    width: 90px;
    position: absolute;
    top: 50%;
    display: block;
    right: 33.48%;
    margin-right: 220px;
    margin-top: -50px;
  }
}
