.homepage-hero {
  z-index: 100;
  background-image: url(/img/home/positano.jpg);
  background-size: cover;
  background-position: center center;

  .homepage-text-headings {
    color: #ffffff;
    h1 {
      font-size: rem(48);
    }
    h2 {
      font-size: rem(20);
      margin: 0 0 4px 0;
    }
  }

  .homepage-hero-outer-content {
    max-width: $max-page-width;
  }
  .homepage-hero-inner-div {
    background-color: rgba(0, 0, 0, 0.5);
    position: relative;
  }

  // .form {
  // }

  &.search-page {
    background-image: none;
    background: $treklee-med-blue1; /* Old browsers 004cff */
    background-image: linear-gradient(
      rgb(1, 145, 255) 0%,
      rgb(0, 76, 255) 100%
    );
  }
  &.search-page > div {
    background-color: transparent;
  }
  // .homepage-hero-outer-content?
  &.search-page > div > .content {
    height: auto;
    min-height: auto;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  &.search-page .form.homepage-search {
    padding-bottom: 20px;
  }
  &.search-page .form.homepage-search > button {
    margin-bottom: 75px;
  }
  &.search-page .search-summary {
    display: none;
  }
}
