@media screen and (max-width: 374px) {
  .homepage-hero {
    .homepage-text-headings {
      h1 {
        font-size: rem(26);
      }
    }
  }
  // .homepage-search .search-form-loyalty-programs {
  //   // position: relative;
  //   // margin-top: -14px;
  //   // padding: 0;
  //   // left: -8px;
  //   // right: initial;

  //   .loyalty-input-btn {
  //     .loyalty-input-btn-text-desktop,
  //     .loyalty-input-btn-text-tablet {
  //       display: none;
  //     }
  //     .loyalty-input-btn-text-phone {
  //       display: initial;
  //     }
  //   }
  // }
}
