@media screen and (min-width: 900px) {
  .homepage-text div.section {
    position: relative;
    padding: 0 50px;
  }

  .homepage-text div.content {
    margin: 0 auto;
    max-width: 1200px;
    height: 100%;
    position: relative;
  }

  .homepage-text .right-image {
    position: absolute;
    right: 0;
    top: 50%;
    width: 35%;
    transform: translateY(-50%);
  }

  .homepage-text .section-1 {
    position: relative;
  }

  .homepage-text .section-1 .text {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    width: 335px;

    transform: translateY(-50%);
  }
  .homepage-text div.section.section-1 img.great {
    position: absolute;
    bottom: 160px;
    left: 160px;
    width: 120px;
  }
  .homepage-text div.section.section-1 img.poor {
    position: absolute;
    bottom: 310px;
    left: 50px;
    width: 110px;
  }
  .homepage-text div.section.section-1 img.airplane {
    padding: 50px 0;
    margin-left: 50px;
    max-height: 350px;
    max-width: 700px;
    display: block;
  }

  .homepage-text div.section.section-3 {
    position: relative;
    padding: 0;
  }

  .homepage-text div.section.section-3 > div {
    max-width: 1200px;
    margin: 0 auto 100px auto;
  }

  .homepage-text .section-3 div.content {
    min-height: auto;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 0;
    max-width: 1200px;
    width: 100%;
  }

  .homepage-text .section-3 .text {
    position: absolute;
    left: 60px;
    top: 50%;
    z-index: 1;
    width: 335px;
    transform: translateY(-50%);
  }

  .homepage-text div.section.section-3 img.bg {
    margin-left: auto;
    width: 40%;
    max-width: 540px;
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  .homepage-text div.section.section-3 img.screen {
    width: 245px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    right: 24.71%;
  }

  .homepage-text div.section.section-3 img.person {
    width: 88px;
    position: absolute;
    top: 43%;
    display: block;
    right: 24.71%;
    margin-right: 210px;
  }

  .homepage-search .locations {
    line-height: 50px;
  }
  .homepage-search .locations > .airport {
    width: calc(50% - 13px);
    padding-left: 47px;
  }
  .homepage-search .locations > .switch {
    width: 50px;
    height: 50px;
    line-height: 50px;
  }

  .homepage-search .locations > .switch svg {
    height: 24px;
  }
  .homepage-search .locations .airport .icon svg {
    width: 32px;
    height: 32px;
  }
  .homepage-search .locations .airport .icon {
    top: 5px;
    left: 5px;
  }
  .homepage-search .airport-autocomplete input {
    font-size: $main-search-form-font-size;
  }
}
