.main-search {
  margin-top: 5px;
}

.main-search .airports {
  display: flex;
  width: 100%;
  height: 38px;
  background-color: #fff;
  border-radius: 4px;
}

.main-search .airports.invalid {
  border-color: #ff5d5d;
  background-color: #ffeded;
}

.main-search .airports > .airport {
  width: 50%;
  box-sizing: border-box;
  padding-left: 30px;
  padding-right: 10px;
  position: relative;
}
.main-search .airports > .airport b {
  position: absolute;
  top: 0;
  left: 0;
  width: 38px;
  height: 38px;
  color: $treklee-dark-blue2;
}
.main-search .airports > .airport b svg {
  width: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
}
.main-search .airports > .airport + .airport:after {
  display: block;
  content: '';
  position: absolute;
  width: 1px;
  left: 0;
  top: 5px;
  height: 28px;
  background-color: $maroon-input-color;
}

// .main-search .invalid .search-calendar .holder {
//   border-color: #ff5d5d;
//   background-color: #ffeded;
// }

@media screen and (min-width: 1251px) {
  .main-search {
    width: 504px;
  }
}

@media screen and (max-width: 1250px) {
  .main-search .airports {
    display: block;
    height: auto;
    background-color: transparent;
    background-color: #fff;
    border-radius: 4px;
  }

  .main-search .airports .airport {
    width: 100%;
    height: 38px;
  }

  .main-search .airports .airport + .airport:after {
    display: none;
  }

  .main-search .airports .airport + .airport {
    /*margin-top: 10px;*/
    border-top: 1px solid #ccc;
    height: 39px;
  }
}

.main-search .dates {
  margin-top: 10px;
  margin-bottom: 10px;
}

.flight-search-result {
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  margin-bottom: 20px;
}
.flight-search-result .flight-item {
  display: flex;
  flex-direction: row;
}
.flight-search-result .flight-item .logos {
  width: 115px;
  border-right: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
}
.flight-search-result .flight-item .logos + div {
  flex: 1;
}
.flight-search-result .expand {
  border-bottom: 1px solid #dcdcdc;
  height: 18px;
  background-color: #f9f9f9;
  text-align: center;
  position: relative;
  width: 100%;
  padding: 0 32px;
  b {
    display: inline-block;
    width: 14px;
    height: 14px;
    position: relative;
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 14px;
      opacity: 0;
    }
  }
}
.flight-search-result .expand::after {
  display: block;
  position: absolute;
  top: -10px;
  bottom: -10px;
  cursor: pointer;
  left: 0;
  right: 0;
  background-color: transparent;
  content: '';
}
.flight-search-result-popup {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 10000;
  overflow: hidden;
}
.flight-search-result-popup .points {
  border-bottom: 0;
}
.flight-search-result-popup h1 {
  background: $treklee-med-blue1;
  background-image: linear-gradient(
    $treklee-med-blue1 0%,
    $treklee-dark-blue1 100%
  );
  color: #fff;
  display: flex;
  flex-direction: column;
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  padding: 0 45px;
  line-height: 45px;
  text-align: center;
}
.flight-search-result-popup h1 b {
  position: absolute;
  top: 0;
  right: 0;
  width: 45px;
  height: 45px;
  cursor: pointer;
}
.flight-search-result-popup h1 b svg {
  height: 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.flight-search-result-popup > div {
  display: flex;
  flex-direction: column;
  height: calc(100% + 2px);
  width: calc(100% + 2px);
  background-color: #fff;
  max-width: 450px;
  max-height: 820px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.flight-search-result-popup > div > .content {
  flex: 1;
  overflow: auto;
}
.flight-search-result-popup h2 {
  margin: 0;
  padding: 0;
  line-height: 36px;
  font-size: 15px;
  font-weight: bold;
  padding-left: 15px;
  border-top: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
}
.flight-search-result-popup h2 i {
  color: #706478;
  font-size: 13px;
  font-style: normal;
  padding-left: 5px;
}
.flight-search-result-popup h3 {
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
  text-align: center;
  line-height: 42px;
  font-size: 13px;
  font-weight: bold;
}
.flight-search-result-popup .center {
  text-align: center;
  padding: 10px;
}
.flight-search-result-popup .price > b,
.flight-search-result-popup .points > b {
  padding-right: 0;
}
.flight-search-result-popup .points,
.flight-search-result-popup .price {
  border-bottom: 1px solid #ccc;
}
.flight-search-result-popup .more-button {
  color: #00bbff;
  text-align: center;
  font-size: 12px;
  line-height: 20px;
  display: inline-block;
  margin: 0 auto;
  cursor: pointer;
}
.flight-search-result-popup .more-button:focus {
  outline: 0;
}
.flight-search-result-popup h3 + .price + .center,
.flight-search-result-popup h3 + .points + .center {
  padding-top: 0;
  margin-top: -7px;
}
.flight-search-result-popup h3.blue {
  background-color: #ebfaff;
}
.flight-search-result-popup .flight-detail-container {
  width: 100%;
  position: relative;
  padding: 10px;
  padding-left: 45px;
  box-sizing: border-box;
}
.flight-search-result-popup .flight-detail-container:after {
  display: block;
  content: '';
  background-color: #00bbff;
  position: absolute;
  top: 20px;
  left: 22px;
  width: 1px;
  bottom: 20px;
}
.flight-search-result-popup .flight-detail:first-child:before {
  position: absolute;
  top: 12px;
  left: -23px;
  border-radius: 11px;
  width: 11px;
  height: 11px;
  box-sizing: border-box;
  background-color: #fff;
  content: '';
  display: block;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border: 1px solid #00bbff;
  z-index: 1;
}
.flight-search-result-popup .flight-detail:last-child:after {
  position: absolute;
  bottom: 2px;
  left: -23px;
  border-radius: 11px;
  width: 11px;
  height: 11px;
  box-sizing: border-box;
  background-color: #fff;
  content: '';
  display: block;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border: 1px solid #00bbff;
  z-index: 1;
}
.flight-search-result-popup .flight-detail .stay:after {
  position: absolute;
  top: 50%;
  left: -23px;
  border-radius: 11px;
  width: 11px;
  height: 11px;
  box-sizing: border-box;
  background-color: #00bbff;
  content: '';
  display: block;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border: 1px solid #00bbff;
  z-index: 1;
}
.flight-search-result-popup .flight-detail {
  padding: 0px;
  font-size: 12px;
  color: #706478;
  line-height: 22px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  > .image {
    top: 0;
    position: absolute;
    left: -115px;
    text-align: center;
    width: 115px;
    padding-top: 30px;
    > img {
      display: block;
      max-width: 90px;
      max-height: 50px;
      margin: 0 auto;
    }
    > span {
      color: #706478;
      font-size: 12px;
      display: block;
    }
  }
  > .stay {
    background-color: #ebfaff;
    margin: 10px 0;
    padding: 5px;
    position: relative;
  }
  > .airline {
    padding: 5px 0;
    padding-left: 60px;
    position: relative;
    background-color: #f9f9f9;
    line-height: 16px;

    margin: 5px 0;
    > img {
      display: block;
      max-width: 40px;
      max-height: 40px;
      margin: 0 auto;
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 30px;
    }
  }
  > .row {
    display: flex;
    flex-direction: row;
    > b {
      width: 65px;
    }
    .date {
      flex: 1;
    }
    .airport {
      flex: 1;
    }
  }
}

/*
.flight-search-result .expand:after{
  display: block;
  content: '';
  top: -10px;
  bottom: -10px;
  background-color: rgba(255, 255, 0, 0.5);
  cursor: pointer;
  left: 0;
  right: 0;
  position: absolute;
}*/

.flight-search-result .flight {
  padding: 16px;
  border-bottom: 1px solid #dcdcdc;
  position: relative;
  min-height: 42px;

  .airline {
    line-height: 20px;
    img {
      max-height: 40px;
      max-width: 90px;
      display: inline-block;
      vertical-align: middle;
    }
    img + img {
      margin-left: 10px;
    }
    span {
      color: #706478;
      font-size: 12px;
      display: inline-block;
      margin-left: 10px;
    }
  }
  .summary {
    min-height: 60px;
    position: relative;
    padding: 11px 110px 0 70px;
    box-sizing: border-box;
    > span {
      position: absolute;
      width: 95px;
      box-sizing: border-box;
      color: #231f20;
      font-size: 15px;
      line-height: 21px;
      padding-top: 10px;
      top: 0;
      > b {
        display: block;
        > b {
          font-size: 12px;
          color: #706478;
          margin-left: 7px;
        }
      }
      i {
        display: block;
      }
      i {
        font-size: 12px;
        color: #706478;
        font-style: normal;
        font-weight: normal;
      }
    }
    > span:first-child {
      left: 0;
      width: 80px;
    }
    > span:last-child {
      right: 0;
    }
    > u {
      display: block;
      position: relative;
      height: 2px;
      background-color: #00bbff;
      margin-top: 10px;
      i {
        display: block;
        position: absolute;
        top: -2px;
        width: 6px;
        height: 6px;
        border-radius: 6px;
        background-color: #00bbff;
        margin-left: -3px;
      }
      i:last-child {
        left: 100%;
        display: none;
      }
      i:first-child {
        left: 0;
        display: none;
      }
    }
    > u.three {
      i:nth-child(2) {
        left: 50%;
      }
    }
    > u.four {
      i:nth-child(2) {
        left: 33%;
      }
      i:nth-child(3) {
        left: 66%;
      }
    }
    > u + i {
      padding-top: 5px;
    }
    > i,
    .stops-labels {
      display: block;
      text-align: center;
      font-style: normal;
      font-size: 12px;
      color: #00bbff;
      line-height: 16px;
      position: relative;
    }
    .stops-labels {
      margin-top: 15px;
    }
    .stops-labels i {
      font-style: normal;
    }
    .stops-labels.three-cities {
      i {
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
      }
      i:first-child {
        left: 50%;
      }
    }
    .stops-labels.four-cities {
      i {
        position: absolute;
        left: 33%;
        top: 0;
        transform: translateX(-50%);
      }
      i:first-child {
        left: 33%;
      }
      i:nth-child(2) {
        left: 66%;
      }
    }
  }
}
.flight-search-result .ariline-names {
  display: none;
}
@media screen and (max-width: 1250px) {
  .flight-search-result .ariline-names {
    display: block;
    font-size: 12px;
    color: #706478;
    padding-left: 15px;
    line-height: 20px;
    padding-top: 15px;
  }
  .flight-search-result .flight {
    padding: 0 10px 16px 10px;
    border-bottom: 0;
    position: relative;
    padding-left: 50px;
    .airline {
      position: absolute;
      top: 18px;
      left: 10px;
      img {
        max-width: 30px;
        transform: translateY(-50%);
      }
      span {
        display: none;
      }
    }
    .summary {
      min-height: 50px;
      overflow: hidden;
      > u + i {
        display: none;
      }
      > u + i + i {
        margin-top: -22px;
        span {
          display: none;
        }
      }
      .stops-labels {
        margin-top: 10px;
      }
    }
  }
  .flight-search-result .flight-item:last-child .flight {
    border-bottom: 1px solid #dcdcdc;
  }
  .flight-search-result .logos {
    display: none;
  }
  .flight-search-result .points > span,
  .flight-search-result .price > span {
    padding-right: 120px;
    display: block;
    > i {
      font-size: 13px;
      line-height: 17px;
      padding: 2px 0;
      cursor: pointer;
    }
  }
}

@media screen and (min-width: 800px) and (max-width: 1250px) {
  .flight-search-result {
    display: flex;
    position: relative;
    .flight-detail > .image {
      display: none;
    }
    .flight-items {
      .flight-item:first-child {
        padding-top: 40px;
      }
      flex: 1;
    }

    .ariline-names {
      position: absolute;
      top: 0;
      left: 10px;
    }
    .prices {
      width: 310px;
      display: flex;
      .points {
        float: left;
        width: 155px;
        border-bottom: 0;
        border-left: 1px solid #dcdcdc;
        box-sizing: border-box;
        padding: 10px;
        span {
          display: block;
          padding-right: 0;
          font-size: 20px;
        }
        span + b {
          position: relative;
          top: auto;
          right: auto;
          margin: 10px auto;
        }
      }
      .price {
        float: left;
        width: 155px;
        border-left: 1px solid #dcdcdc;
        box-sizing: border-box;
        padding: 10px;
        span {
          display: block;
          padding-right: 0;
          font-size: 20px;
        }
        span + b {
          position: relative;
          top: auto;
          right: auto;
          margin: 10px auto;
        }
      }
    }
  }
  .flight-search-result .flight-item:last-child .flight {
    border-bottom: 0;
  }
}
@media screen and (min-width: 1251px) {
  .flight-search-result .flight {
    padding: 16px;
    .airline {
      display: none;
    }
    .summary {
      padding: 10px 140px 0 100px;
      > span {
        width: 130px;
        font-size: 20px;
        i {
          font-size: 14px;
        }
      }
      > span + u + i + i {
        margin-top: -25px;
      }
      > span + u + i {
        display: none;
      }
      > span:first-child {
        width: 100px;
      }
    }
  }
  .flight-search-result .logos {
    text-align: center;
    padding-top: 20px;
    > * {
      opacity: 1;
      transition: opacity 0.2s ease-in-out;
    }
    img {
      max-height: 50px;
      display: inline-block;
      vertical-align: middle;
      max-width: 90px;
    }
    img + img {
      margin-left: 10px;
    }
    span {
      color: #706478;
      font-size: 12px;
      display: block;
      margin-top: 10px;
    }
  }
  .flight-search-result .logos.hide > * {
    opacity: 0;
  }
}
.flight-search-result-popup .points,
.flight-search-result .points {
  padding: 16px;
  border-bottom: 1px solid #dcdcdc;
  position: relative;
  min-height: 42px;
  > span {
    line-height: 21px;
    font-size: 25px;
    color: #231f20;
    font-weight: bold;
    position: relative;
    display: block;

    > span {
      position: relative;
      /**/
      u {
        font-size: 12px;
        text-decoration: none;
      }
    }

    i {
      font-weight: normal;
      display: block;
      font-size: 13px;
      font-style: normal;
      > u {
        position: relative;
        width: 25px;
        display: inline-block;
        text-decoration: none;
        color: transparent;
        > svg {
          position: absolute;
          top: -2px;
          left: 0;
          height: 20px;
        }
      }
      > b {
        position: relative;
        padding-left: 15px;
        > svg {
          position: absolute;
          top: 50%;
          left: 0;
          margin-top: -9px;
          height: 15px;
          margin-left: 6px;
        }
      }
    }
  }
  > b {
    display: block;
    position: absolute;
    top: 16px;
    right: 16px;
    width: 110px;
    height: 40px;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: $treklee-dark-blue2;
    font-size: 14px;
    color: #fff;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
    font-weight: normal;
    padding-right: 30px;
    > span {
      display: block;
    }
    > u {
      position: absolute;
      top: 0;
      right: -0px;
      height: 40px;
      width: 30px;
      background-color: rgba(0, 0, 0, 0);
      display: block;
    }
    > u:before {
      display: block;
      content: '';
      position: absolute;
      top: 9px;
      bottom: 9px;
      width: 1px;
      background-color: #fff;
      left: 0;
    }
    > u:after {
      display: block;
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 7px;
      height: 7px;
      border: 1px solid #fff;
      border-left: 0;
      border-top: 0;
      -webkit-transform: rotate(45deg) translateX(-50%);
      transform: rotate(45deg) translateX(-50%);
      margin-top: -4px;
    }
  }
}
.flight-search-result-popup .price,
.flight-search-result .price {
  padding: 16px;
  position: relative;
  min-height: 42px;
  > span {
    line-height: 21px;
    font-size: 25px;
    color: #231f20;
    font-weight: bold;
    > span > u,
    > u {
      text-decoration: none;
      font-size: 13px;
    }
    i {
      font-weight: normal;
      display: block;
      font-size: 13px;
      font-style: normal;
      img {
        max-height: 30px;
        max-width: 100px;
      }
    }
  }
  > a,
  > b {
    display: block;
    position: absolute;
    top: 16px;
    right: 16px;
    width: 110px;
    height: 40px;
    border-radius: 5px;
    background-color: #00bbff;
    font-size: 14px;
    color: #fff;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
    font-weight: normal;
    text-decoration: none;
    padding-right: 30px;
    box-sizing: border-box;
    > a {
      display: block;
      text-align: center;
      color: #fff;
      text-decoration: none;
    }
    > u {
      position: absolute;
      top: 0;
      right: -0px;
      height: 40px;
      width: 30px;
      background-color: rgba(0, 0, 0, 0);
      display: block;
    }
    > u:before {
      display: block;
      content: '';
      position: absolute;
      top: 9px;
      bottom: 9px;
      width: 1px;
      background-color: #fff;
      left: 0;
    }
    > u:after {
      display: block;
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 7px;
      height: 7px;
      border: 1px solid #fff;
      border-left: 0;
      border-top: 0;
      -webkit-transform: rotate(45deg) translateX(-50%);
      transform: rotate(45deg) translateX(-50%);
      margin-top: -4px;
    }
  }
}

.flight-search-result-popup .points > b,
.flight-search-result-popup .price > b {
  padding-right: 0;
}
.flight-search-result-popup > div > ul {
  display: none;
}
.flight-search-result-popup .header.table {
  display: none;
}

@media screen and (min-width: 960px) {
  .flight-search-result.expanded {
    border-radius: 5px 5px 0 0;
    margin-bottom: 0;
    border-bottom: 0;
  }
  .flight-search-result-popup.fares .header.table {
    line-height: 50px;
    font-size: 14px;
    font-weight: bold;
    display: block;
    position: relative;
    border-bottom: 1px solid #dcdcdc;
    padding: 0 16px;
  }
  .flight-search-result-popup.fares .header.table span + span {
    position: absolute;
    top: 0;
    left: 50%;
  }

  .flight-search-result-popup > div > ul {
    background-color: #f9f9f9;
    line-height: 46px;
    margin: 0;
    padding: 0;
    border-top: 4px solid #eaeaea;
    display: block;
  }
  .flight-search-result-popup > div > ul li {
    margin: 0 10px;
    padding: 0 20px;
    list-style: none;
    display: inline-block;
    font-size: 15px;
    font-weight: bold;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    cursor: pointer;
  }
  .flight-search-result-popup.schedule h3,
  .flight-search-result-popup.schedule .price,
  .flight-search-result-popup.schedule .points,
  .flight-search-result-popup.schedule .price + .center,
  .flight-search-result-popup.schedule .points + .center {
    display: none;
  }
  .flight-search-result-popup.fares h3 {
    line-height: 50px;
    font-size: 15px;
  }
  .flight-search-result-popup.fares .price + .center ~ * {
    display: none;
  }
  .flight-search-result-popup > div > ul li.selected {
    color: #00bbff;
    border-bottom: 2px solid #00bbff;
  }
  .flight-search-result-popup > div > h1 {
    display: none;
  }
  .flight-search-result-popup > div {
    display: block;
    flex-direction: column;
    height: auto;
    width: 100%;
    background-color: transparent;
    max-width: initial;
    max-height: initial;
    position: relative;
    top: auto;
    left: auto;
    transform: none;
  }
  .flight-search-result-popup {
    position: relative;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    background-color: transparent;
    z-index: auto;
    overflow: visible;
    border: 1px solid #dcdcdc;
    border-radius: 0 0 5px 5px;
    border-top: 0;
    margin-bottom: 20px;
  }
  .flight-search-result-popup .points span,
  .flight-search-result-popup .price span {
    span {
      position: absolute;
      left: 50%;
      u {
        display: none;
      }
    }
  }

  .flight-search-result-popup .price,
  .flight-search-result-popup .points {
    min-height: 40px;
    span span {
      font-size: 20px;
      line-height: 40px;
    }
    span i {
      font-size: 17px;
      line-height: 40px;
    }
  }
}

.search-results-loader {
  padding: 20px;
}
.search-results-loader svg {
  margin: 0 auto;
  display: block;
  width: 60px;
  height: 60px;
}

.search-placeholder {
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  margin-bottom: 10px;
  min-height: 100px;
  position: relative;
}
.search-placeholder span,
.search-placeholder b,
.search-placeholder i {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#d9e1e8+0,f1f4f7+50,d9e1e8+100 */
  background: #d9e1e8; /* Old browsers */
  background: -moz-linear-gradient(
    left,
    #d9e1e8 0%,
    #f1f4f7 50%,
    #d9e1e8 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #d9e1e8 0%,
    #f1f4f7 50%,
    #d9e1e8 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    #d9e1e8 0%,
    #f1f4f7 50%,
    #d9e1e8 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d9e1e8', endColorstr='#d9e1e8',GradientType=1 ); /* IE6-9 */
  display: block;
  height: 15px;
  position: absolute;
}

.search-placeholder i {
  top: 30px;
  width: 100px;
  right: 30px;
  height: 40px;
}
.search-placeholder b {
  top: 20px;
  left: 20px;
  right: 150px;
}
.search-placeholder span {
  top: 60px;
  left: 20px;
  right: 200px;
}

@media screen and (min-width: 1550px) {
  .flight-search-result .flight .airline {
    display: block;
  }
  .flight-search-result .ariline-names {
    display: block;
    font-size: 12px;
    color: #706478;
    padding-left: 15px;
    line-height: 20px;
    padding-top: 15px;
  }
  .flight-search-result .flight {
    padding: 0 10px 16px 10px;
    border-bottom: 0;
    position: relative;
    padding-left: 50px;
    .airline {
      position: absolute;
      top: 18px;
      left: 10px;
      img {
        max-width: 30px;
        transform: translateY(-50%);
      }
      span {
        display: none;
      }
    }
    .summary {
      min-height: 50px;
      overflow: hidden;
      > u + i {
        display: none;
      }
      > u + i + i {
        margin-top: -22px;
        span {
          display: none;
        }
      }
      .stops-labels {
        margin-top: 10px;
      }
    }
  }
  .flight-search-result .flight-item:last-child .flight {
    border-bottom: 1px solid #dcdcdc;
  }
  .flight-search-result .logos {
    display: none;
  }
  .flight-search-result .points > span,
  .flight-search-result .price > span {
    padding-right: 120px;
    display: block;
    > i {
      font-size: 13px;
      line-height: 17px;
      padding: 2px 0;
      cursor: pointer;
    }
  }
  .flight-search-result {
    display: flex;
    position: relative;
    .flight-detail > .image {
      display: none;
    }
    .flight-items {
      .flight-item:first-child {
        padding-top: 40px;
      }
      flex: 1;
    }

    .ariline-names {
      position: absolute;
      top: 0;
      left: 10px;
    }
    .prices {
      width: 310px;
      border-left: 1px solid #dcdcdc;
      .points {
        border-bottom: 0;
        box-sizing: border-box;
        padding: 10px;
        padding-right: 125px;
        box-sizing: border-box;
        span {
          display: block;
          padding-right: 0;
          font-size: 20px;
        }
        span + b {
          position: absolute;
          top: 10px;
          right: 10px;
        }
      }
      .price {
        box-sizing: border-box;
        padding: 10px;
        padding-right: 125px;
        box-sizing: border-box;
        span {
          display: block;
          padding-right: 0;
          font-size: 20px;
        }
        span + b {
          position: absolute;
          top: 10px;
          right: 10px;
        }
      }
    }
  }
  .flight-search-result .flight-item:last-child .flight {
    border-bottom: 0;
  }
}

.search-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  z-index: 10000;
  background-color: #f0f0f0;

  .search-loader-bar {
    width: 0;
    height: 5px;
    background-color: #00aceb;
    transition: width 0.5s ease-in-out;
  }
}

.flight-options-select > div > div > div:focus,
.sort-select > div > div > div:focus {
  background-color: transparent;
}

.filters-mobile-button {
  display: block;
  position: fixed;
  bottom: 16px;
  left: 50%;
  width: 110px;
  height: 40px;
  border-radius: 40px;
  background-color: #0194ff;
  font-size: 14px;
  color: #fff;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  font-weight: normal;
  text-decoration: none;
  box-sizing: border-box;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transform: translateX(-50%);
}
@media screen and (min-width: 960px) {
  .filters-mobile-button {
    display: none;
  }
}
.search-summary {
  padding: 10px;
  & > div {
    line-height: 40px;
    border-radius: 5px;
    color: $maroon-input-color;
    box-sizing: border-box;
    width: 100%;
    font-size: 13px;
    height: 40px;
    background-color: #fff;
    text-align: center;
  }
  & > div > span {
    line-height: 40px;
    display: inline-block;
    // margin-right: 4px;
  }
  b {
    position: relative;
    width: 40px;
    height: 40px;
    display: inline-block;
  }
  b:before {
    content: ' ';
    display: inline-block;
  }
  b > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 20px;
  }
  u {
    text-decoration: none;
  }
  i {
    display: inline-block;
    padding-left: 10px;
    margin-left: 10px;
    padding-right: 10px;
    margin-right: 5px;
    font-style: normal;
    position: relative;
  }
  i:before {
    display: block;
    content: '';
    position: absolute;
    top: 7px;
    bottom: 7px;
    left: 0;
    width: 1px;
    background-color: #dcdcdc;
  }
  i:after {
    display: block;
    content: '';
    position: absolute;
    top: 7px;
    bottom: 7px;
    right: 0;
    width: 1px;
    background-color: #dcdcdc;
  }
}
