@media screen and (max-width: 899px) and (min-width: 500px) {
  .homepage-partners .partners > div {
    width: 33.333%;
  }
  .homepage-partners .partners a {
    display: inline-block;
    float: left;
  }

  .homepage-partners .partners:after {
    display: block;
    content: '';
    clear: both;
  }
}
