// .search-form-container {
// }

.airport-autocomplete {
  position: relative;

  .back-button-mobile {
    display: none;
  }

  .airport-icon {
    display: none;
  }

  input {
    border: 0;
    width: 100%;
    line-height: 40px;
    height: 40px;
    padding: 0 10px;
    font-size: 15px;
    background-color: transparent;
    @include borderBoxMixin;

    &:focus {
      outline: none;
    }

    // &.input-basic {}
  }

  .results {
    display: none;
    color: #333;
    background-color: #fff;
    position: absolute;
    top: 43px;
    left: -37px;
    right: 0;
    z-index: 2000;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    padding: 10px 0;
    border-radius: 5px;
    width: 504px;
  }

  .results.open {
    display: block;
  }

  .results > svg {
    display: none;
  }

  .results.loading svg {
    display: block;
    width: 40px;
    margin: 0px auto;
  }

  .results .airport {
    position: relative;
    line-height: 36px;
    font-size: 14px;
    padding: 0 20px;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 55px;
    padding-left: 45px;

    b {
      position: absolute;
      left: 10px;
      width: 36px;
      height: 36px;
      color: #777;

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 20px;
      }
    }

    span {
      color: #999;
      position: absolute;
      top: 0;
      right: 20px;
    }
  } // end .results .airport

  .results .airport.selected {
    background-color: #e0e0e0;
  }
}

.airport-autocomplete.to .results {
  left: -47px;
  right: auto;
}

.airport-autocomplete.mobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 2000;
  @include borderBoxMixin;
  display: flex;
  flex-direction: column;
  .bar {
    height: 55px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    padding-left: 45px;
    background-color: #fff;
    z-index: 2001;
    padding-right: 50px;
    input {
      line-height: 21px;
      height: 55px;
      padding: 17px 0;
      @include borderBoxMixin;
      font-size: 16px;
    }
    .back-button-mobile {
      position: absolute;
      top: 0;
      right: 10px;
      display: block;
      span {
        line-height: 55px;
      }
    }
    .airport-icon {
      width: 45px;
      height: 55px;
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      color: $treklee-dark-blue2;
      fill: $treklee-dark-blue2;
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 24px;
      }
    }
  }
  .content {
    flex: 1;
    @include borderBoxMixin;
    padding: 15px;
    overflow: auto;
  }
  .results {
    position: relative;
    top: auto;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    width: auto;
    left: auto;
  }
}
