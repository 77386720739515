// All media queries are imported at bottom

// UTILITIES

.homepage-link {
  color: $treklee-dark-blue2;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 2px;
  margin-top: 15px;
}

.homepage-large-text-header {
  margin: 0;
  padding: 0 10px;
  text-align: center;
  text-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  font-size: 39px;
  line-height: 1.49;
  font-weight: normal;
  color: #ffffff;
}

// SECTIONS

@import 'homepage_hero.scss';
@import 'homepage_search.scss';
@import 'homepage_boxes.scss';
@import 'homepage_partners.scss';
@import 'homepage_destination';
@import 'homepage_text.scss';
@import 'homepage_steps';
@import 'homepage_signup';
@import 'homepage_footer';

// MEDIA QUERIES

// these are in the correct cascading order!
// max -> descending order
@import 'media_queries/_max_1150.scss';
@import 'media_queries/_max_899.scss';
@import 'media_queries/_max_800.scss';
@import 'media_queries/_max_699.scss';
@import 'media_queries/_max_599.scss';
@import 'media_queries/_max_500.scss';
@import 'media_queries/_max_374.scss';

// min -> ascending order
@import 'media_queries/_min_700.scss';
@import 'media_queries/_min_900.scss';
@import 'media_queries/_min_1100.scss';

// min/max -> least to most specific
@import 'media_queries/_min_700_max_1099.scss';
@import 'media_queries/_min_500_max_899.scss';
@import 'media_queries/_min_700_max_899.scss';
@import 'media_queries/_min_600_max_620.scss';
