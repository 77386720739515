:root,
html {
  font-size: 100%;
}

html {
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  overflow: auto;
  font-size: rem(16);
  @include borderBoxMixin;
}

.app {
  width: 100%;
  overflow-x: hidden;
}

/* FONTS */
// @import url('https://fonts.googleapis.com/css?family=Lato:400,700,400italic,700italic&subset=latin');
// @import url($main-font-url);
// @import url('CircularStd-Medium.otf') format("opentype");
@import url($second-font-url);

body,
h1,
h2,
h3,
h4,
h5 {
  font-family: $main-font-family, $second-font-family;
  font-weight: normal;
}

a {
  font-family: $main-font-family, $second-font-family;
}
