@media screen and (max-width: 1099px) and (min-width: 700px) {
  .homepage-steps {
    text-align: center;
    padding-bottom: 100px;
  }

  .homepage-steps .text {
    margin: 0 auto;
    width: 450px;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .homepage-steps .text h2:after {
    display: block;
    content: '';
    position: absolute;
    top: -40px;
    left: 50%;
    margin-left: -25px;
    background-color: #00bbff;
    width: 50px;
    height: 2px;
  }

  .homepage-steps .text h2,
  .homepage-steps .text p {
    max-width: 450px;
  }

  .homepage-steps .text h2 br {
    display: none;
  }

  .homepage-steps .card {
    position: relative;
    display: inline-block;
    margin: 0 10px;
  }

  .homepage-hero {
    // .logo-link {
    //   left: 15px;
    // }
    .homepage-hero-outer-content {
      margin: 0 auto;
      min-height: 430px;
      height: 100%;
      position: relative;
      padding-top: 80px;
    }
    // .homepage-large-text-header{}
  }

  .homepage-partners {
    position: relative;
    padding: 0 50px;
  }

  .homepage-partners .content {
    margin: 0 auto;
    max-width: 1200px;
    height: 100%;
    position: relative;
  }

  .homepage-partners .partners {
    position: relative;
  }
}
