.search-filter-holder {
  padding-right: 20px;
  padding-top: 40px;
}
.search-filter-container {
  border-radius: 6px;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
  width: 250px;
}
.search-filter-container > div.splash {
  padding: 15px;
  text-align: center;
}
.search-filter-panel .title {
  line-height: 44px;
  font-size: 16px;
  color: #706478;
  padding-left: 15px;
}
.search-filter-panel .content {
  padding: 0 15px 15px 15px;
  width: 100%;
  position: relative;
}
.search-filter-holder.disabled .search-filter-panel .content {
  opacity: 0.2;
}
.search-filter-holder.disabled .search-filter-panel .content:after {
  display: block;
  content: '';
  position: absolute;
  background-color: rgba(0, 0, 0, 0);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.search-filter-panel + .search-filter-panel {
  border-top: 1px solid #dcdcdc;
}
.search-filter-panel .checkbox-holder span.label {
  font-size: 14px;
  color: #706478;
  padding-left: 5px;
  line-height: 24px;
  display: inline-block;
  cursor: pointer;
}
.filter-label {
  color: #706478;
  font-size: 14px;
  font-weight: bold;
}
.range-slider + .filter-label {
  margin-top: 20px;
}
.rc-slider-rail {
  background-color: #dcdcdc;
}
.rc-slider-track {
  background-color: #00bbff;
}
.rc-slider-handle {
  border: solid 2px #00bbff;
  background-color: #00bbff;
}
.rc-slider-handle:focus {
  border-color: #00bbff;
}
.range-slider span {
  color: #706478;
  font-size: 14px;
  line-height: 30px;
  display: block;
}
@media screen and (max-width: 960px) {
  .search-filter-holder {
    padding-right: 0;
    padding-bottom: 0px;
    padding-top: 0;
  }
  .search-filter-container {
    width: 100%;
    display: none;
  }
  .mobile-filters .search-filter-container {
    display: block;
    right: 0;
    position: fixed;
    background-color: #fff;
    top: 0;
    left: 0;
    padding: 10px;
    z-index: 10000;
    box-sizing: border-box;
    overflow: auto;
    bottom: 0;
    border-radius: 0;
    border: 0;
  }
}

div.checkbox {
  position: relative;
  padding: 5px 0 5px 25px;
}
div.checkbox input {
  position: absolute;
  top: 5px;
  left: 0;
  appearance: none;
  width: 17px;
  height: 17px;
  background-color: rgba(0, 0, 0, 0);
  margin: 0;
  opacity: 0;
  cursor: pointer;
}
div.checkbox b {
  position: absolute;
  top: 4px;
  left: 0;
  width: 19px;
  height: 19px;
  box-sizing: border-box;
  border: 1px solid #706478;
  border-radius: 3px;
}
div.checkbox.selected b {
  background-color: #00bbff;
  border-color: #00bbff;
}
div.checkbox.selected b:after {
  display: block;
  content: '';
  width: 8px;
  height: 4px;
  border: 2px solid #fff;
  border-top: 0;
  border-right: 0;
  transform: rotate(-45deg);
  top: 50%;
  left: 50%;
  position: absolute;
  margin-top: -5px;
  margin-left: -6px;
}
div.checkbox span {
  color: #706478;
  font-size: 14px;
  line-height: 18px;
  display: inline;
  cursor: pointer;
}
div.checkbox span + span {
  opacity: 0;
  margin-left: 5px;
  color: #ccc;
  transition: opacity 0.2s;
}
div.checkbox:hover span + span {
  opacity: 1;
}

.search-results .load-more-button {
  display: block;
  text-align: center;
  padding-bottom: 30px;
}

.search-results .load-more-button b {
  display: inline-block;
  padding: 0 15px;
  height: 40px;
  border-radius: 5px;
  background-color: #00bbff;
  font-size: 14px;
  color: #fff;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  font-weight: normal;
  text-decoration: none;
  box-sizing: border-box;
  text-align: center;
  outline: 0;
}

.search-filter-container .reset {
  position: absolute;
  top: -37px;
  line-height: 30px;
  color: #00bbff;
  right: 50px;
  font-size: 13px;
  cursor: pointer;
  outline: none;
}
.search-filter-container .link {
  line-height: 30px;
  color: #00bbff;
  font-size: 13px;
  border: 0;
  outline: 0;
  background-color: transparent;
  outline: none;
  cursor: pointer;
}

@keyframes placeholderShimmer {
  0% {
    background-position: 115% 0;
  }
  to {
    background-position: -115% 0;
  }
}
.progress-loading {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;
  background: linear-gradient(to right, #e0e7ed 30%, #f1f4f7 50%, #e0e7ed 70%);
  background-size: 800px 104px;
  height: 18px;
  margin: 14px 0 5px;
}
.progress-loading.line {
  height: 1px;
}
.progress-loading.a {
  height: 14px;
}
.progress-loading.b {
  height: 18px;
}
.progress-loading.c {
  height: 34px;
}
.search-error-results,
.search-no-results {
  font-size: 24px;
  padding-top: 40px;
  text-align: center;
}
.search-filters {
  line-height: 32px;
  font-size: 13px;
}
.search-no-results b {
  display: block;
  padding: 0 15px;
  height: 40px;
  border-radius: 5px;
  background-color: #00bbff;
  font-size: 14px;
  color: #fff;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  font-weight: normal;
  text-decoration: none;
  box-sizing: border-box;
  text-align: center;
  outline: 0;
  margin: 20px auto;
  width: 120px;
}

.content-container-main .ads {
  padding-top: 40px;
  padding-left: 20px;
}
.content-container-main .ads > * {
  width: 300px;
  background-color: #f5f5f5;
  text-align: center;
  box-sizing: border-box;
  padding-top: 20px;
  box-sizing: border-box;
}
.content-container-main .ads > * + * {
  margin-top: 20px;
}
.content-container-main .ads > .ad-1 {
  height: 250px;
}
.content-container-main .ads > .ad-2 {
  height: 600px;
}
@media screen and (max-width: 1250px) {
  .content-container-main .ads {
    display: none;
  }
}
