.homepage-footer {
  padding: 0 50px;
  background-color: #3b3340;
}

.homepage-footer .content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px 0;
  position: relative;
  text-align: center;
}

.homepage-footer .content:after {
  display: block;
  clear: both;
  content: '';
}

.homepage-footer .content .logo {
  max-width: 190px;
  margin: 0 auto;
  display: block;
  margin-bottom: 30px;
}
.homepage-footer .content > a {
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 34px;
  letter-spacing: normal;
  color: #bdbdbd;
  text-decoration: none;
  margin: 0 15px;
  transition: color 0.2s;
}
.homepage-footer .content > a:hover {
  color: #fff;
}
.homepage-footer .content > a.icon {
  margin: 30px 9px 10px 9px;
  line-height: 23px;
  height: 23px;
  display: inline-block;
}
.homepage-footer .content > a svg {
  height: 23px;
}
