.homepage-boxes {
  padding: 50px;
}
.homepage-boxes > .section {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
}
.homepage-boxes > .section > div {
  width: 33.3333%;
  display: block;
}
.homepage-boxes > .section > div:nth-child(1) {
  text-align: left;
  padding-right: 20px;
}
.homepage-boxes > .section > div:nth-child(2) {
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}
.homepage-boxes > .section > div:nth-child(3) {
  text-align: right;
  padding-left: 20px;
}
.homepage-boxes > .section > div .box {
  max-width: 315px;
  min-height: 100%;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  display: block;
  text-align: center;
  box-sizing: border-box;
  padding-top: 20px;
}
.homepage-boxes > .section > div:nth-child(2) .box {
  margin: 0 auto;
}
.homepage-boxes > .section > div:nth-child(3) .box {
  margin-left: auto;
}
.homepage-boxes > .section > div .box b {
  width: 48px;
  height: 48px;
  position: relative;
  margin: 0 auto;
  display: block;
  color: $treklee-dark-blue2;
}
.homepage-boxes > .section > div .box b svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 48px;
}
.homepage-boxes > .section > div .box h2 {
  font-family: inherit;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $maroon-input-color;
  padding: 10px;
  margin: 0;
  position: relative;
  width: 100%;
  display: block;
  max-width: 335px;
  margin-bottom: 0px;
  box-sizing: border-box;
  text-align: center;
  margin-top: 10px;
}
.homepage-boxes > .section > div .box p {
  margin: 0;
  font-size: 15px;
  font-weight: normal;
  line-height: 1.53;
  color: $maroon-input-color;
  padding: 0 20px;
  margin-top: 5px;
  padding-bottom: 20px;
}
