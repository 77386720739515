$mobile-padding: 16px;

.passenger-count {
  display: inline-block;
  margin-right: 20px;
  font-weight: 500;
  position: relative;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  align-self: center;
  margin-top: 16px;
  margin-bottom: 8px;

  .passenger-count-button-container {
    outline: none;
    .passenger-count-button {
      font-weight: 500;
      cursor: pointer;
      padding-right: 26px; // this was 30px
      position: relative;
      display: inline-block;
      line-height: 32px;
      .passenger-count-button-dropdown-icon {
        position: absolute;
        top: 4px;
        right: 0;
        svg {
          height: 24px;
          width: 24px;
          fill: #fff;
        }
      }
    }
  }
}

// .passenger-count-popover is a child of <body>
body {
  .passenger-count-content {
    padding: 15px;
    color: #333;
    width: 250px;
    .passenger-count-submit-buttons {
      text-align: right;
      button:last-child {
        color: $treklee-dark-blue2;
      }
    }
    .passenger-type-container {
      padding-bottom: 14px;
      line-height: 16px;
      position: relative;
      min-height: 51px;
      box-sizing: border-box;
      &:first-child .passenger-type {
        padding-top: 7px;
      }
      .passenger-type {
        font-size: 16px;
        line-height: 19px;
        font-weight: normal;
      }
      .passenger-type-info {
        font-size: 13px;
        padding-top: 0px;
        font-style: normal;
      }
      .passenger-type-buttons {
        .passenger-type-buttons-temp-value {
          font-size: 14px;
          line-height: 32px;
          position: absolute;
          right: 32px;
          text-align: center;
          top: 0;
          width: 48px;
        }
        button {
          background-color: #e8f0fe;
          color: #1a73e8;
          height: 32px;
          line-height: 32px;
          min-width: initial;
          padding: 0;
          position: absolute;
          right: 80px;
          top: 0;
          width: 32px;

          &:disabled {
            background-color: #f5f6f7;
            color: #bdbdbd;
          }
          &:last-child {
            right: 0;
          }
        }
      }
    }
    .passenger-count-validation-error {
      font-size: 14px;
      color: $error1;
    }
  }
}

.passenger-count-mobile-dialog {
  h4 {
    font-size: rem(24);
  }

  .passenger-count-mobile-dialog-title {
    text-align: center;
    padding-top: 16px;
  }

  .passenger-count-content {
    width: initial;
    max-width: 450px;
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
  }

  .passenger-count-wrapper {
    padding-left: $mobile-padding;
    padding-right: $mobile-padding;
  }

  .cabin-container {
    .cabin-buttons-wrapper {
      @include borderBoxMixin;
      margin: 24px auto 0;
      padding: 0 $mobile-padding;
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      button {
        // border: 1px solid $muiBorderColor;
        width: 157px;
        height: 38px;

        &:first-child {
          margin-bottom: 24px;
        }
      }

      .cabin-btn-active {
        border: 2px solid $treklee-dark-blue1;
      }
    }
  }
}

@media screen and (max-width: 355px) {
  .passenger-count-mobile-dialog {
    .cabin-container {
      .cabin-buttons-wrapper {
        button {
          width: 140px;
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
}
