.homepage-text h2 {
  font-family: inherit;
  font-size: 34px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $maroon-input-color;
  padding: 0;
  margin: 40px 0 24px 0;
  position: relative;
  width: 100%;
  display: block;
  max-width: 335px;
}

.homepage-text h2:after {
  display: block;
  content: '';
  position: absolute;
  top: -40px;
  left: 0;
  background-color: #00bbff;
  width: 50px;
  height: 2px;
}

.homepage-text p {
  width: 100%;
  display: block;
  max-width: 335px;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.53;
  letter-spacing: normal;
  color: $maroon-input-color;
  margin: 0;
  padding: 0;
}

.homepage-text div.section.section-3 .images {
  min-height: 350px;
}

.homepage-text01 {
  position: relative;

  & > img.bg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 40%;
    max-width: 540px;
  }
  & > .section-1 {
    margin: 15% auto;
  }
}
