// My Account related utilities
.account-pages-container {
  background-color: #f8f9fb;

  h2 {
    margin: 0 0 24px 0;
    font-weight: bold;
    font-size: rem(20);
  }
}

.layout-page-padding {
  padding: 8px 16px;
}

.account-flex {
  display: flex;
  justify-content: space-between;
}

// not used?
.field-error {
  color: $error1;
  margin: 0 auto;
  text-align: center;
}
