// Eventually, this will replace _loyalty_selector.scss

$border: 2px;
$leftRightPadding: 22px;
$col-2: 380px;
$col-2-dsktp: 414px;
$col-3: 596px;
$col-4: 776px;
$bottom-offset-mobile: 0px;
$button-offset-mobile: 62px;

// only used if screen height is above certain amount
$desktop-view-height: 66vh;

// tablet note: Chrome has lower height than Safari
// landscape tablet 56vh
$tablet-landscape-view-height: 56vh;
// this is for tall skinny tablet
$tablet-view-height: 73vh;
$mobile-view-height: 60vh;
// iphone SE
$mobile-sm-view-height: 54vh;
$dialog-title-margin-left: 26px;
$award-wallet-btn-height: 56px;

.loyalty-selection-poc {
  div {
    @include borderBoxMixin;
  }
  .loyalty-selection-dialog-title {
    padding: 16px 22px;
    border-bottom: $mui-grey-border;

    h4 {
      margin-left: $dialog-title-margin-left;
    }
  }

  .flex-content {
    display: flex;
    flex: 1 1 auto;
  }

  .search-and-programs {
    border-right: $mui-grey-border;
    width: $col-4;

    .search-wrapper {
      padding: 16px 34px 8px $leftRightPadding;
    }

    // class added on click
    &.show-chips {
      .search-wrapper,
      .loyalty-programs-status-container {
        display: none;
      }
    }

    // .search-input {}
  }

  .loyalty-programs-status-container {
    padding: 8px 16px 0 16px;
  }
  .loyalty-programs-list-container {
    margin: 0 auto;
    height: $tablet-landscape-view-height;
    overflow-y: auto;
    width: auto;
    // display: flex;
    // flex-wrap: wrap;

    .no-programs-found {
      width: 100%;
      text-align: center;
      padding: 12px 0;

      .alert-icon-container {
        padding-bottom: 8px;
        color: $error1;
        svg {
          width: 50px;
          height: 50px;
        }
      }

      .btn-container {
        padding-top: 16px;

        button {
          background-color: $treklee-light-blue1;
        }
      }
    }

    .loyalty-programs-list-results {
      display: flex;
      flex-wrap: wrap;
    }

    .loyalty-card-content {
      height: 100%;
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      .loyalty-card-image-container {
        width: calc(100% - 32px);
        margin: 16px auto 8px;

        img {
          width: 100%;
          min-height: 33px;
          max-height: 46px;
        }
      }

      .loyalty-card-label {
        text-align: center;
        margin: 0 4px 8px;
        font-size: rem(13);
      }
    }
  }

  .aside-section {
    flex: 1;
    min-width: 290px;

    .award-wallet-section {
      div {
        padding-left: $leftRightPadding;
        padding-right: 2px;
        // padding-right: $leftRightPadding;

        &.award-wallet-btn-containers {
          padding-top: 16px;
          padding-bottom: 8px;
        }
      }

      .selected-chips-btn-mobile {
        display: none;
        color: white;
        background-color: $treklee-light-blue1;
      }
    }
  }
}

.award-wallet-subtext h6 {
  color: $light-grey-text1;
  font-size: rem(14);
}

.award-wallet-btn-container-mobile {
  border-top: $mui-grey-border;
  display: none;
  width: 100%;
  margin-top: 8px;
  padding: 10px 0 10px 10px;
  text-align: center;

  div {
    @include borderBoxMixin;
  }

  button.award-wallet-btn {
    // mobile modifications only here
    margin-bottom: 8px;
  }

  h6 {
    font-size: rem(16);
  }
}

button.award-wallet-btn {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 0px 16px;
  // award wallet button needs height for Safari
  height: $award-wallet-btn-height;
  span {
    height: $award-wallet-btn-height;
  }
  img {
    width: 48px;
    height: $award-wallet-btn-height;
  }
}

.loyalty-selection-dialog-title {
  div {
    @include borderBoxMixin;
  }
  .subtitle {
    margin-top: 8px;
    color: $light-grey-text1;
  }
}

.loyalty-chip-poc {
  margin: 1px;
  width: 200px;

  div {
    @include borderBoxMixin;
  }

  &.MuiChip-root {
    background-color: $treklee-light-blue1;
    border-radius: 4px;
    color: white;
    justify-content: space-between;

    &:focus {
      background-color: $treklee-med-blue1;
    }
  }
  .MuiChip-deleteIcon {
    height: 14px;
  }
  .MuiChip-deleteIconColorPrimary {
    color: white;
  }
}

// don't put {children} css in here
.card-outline-container {
  display: inline-block;
  margin: 8px;

  & div {
    box-sizing: content-box;
  }

  .airline-card {
    border: transparent;
    margin: 0 $border $border $border;

    // &:hover {
    //   border: $border solid #e4e4e4;
    //   margin: -$border 0 0 0;
    // }
  }

  .airline-card-outlined {
    border: $border solid $treklee-dark-blue2;
    margin: -$border 0 0 0;
  }
}

.selected-chips-container {
  display: block;
  max-height: 476px;
  max-width: 400px;
  overflow-y: auto;
  padding: 16px 0;

  &.mob {
    max-height: 75vh;
    min-height: 200px;
    width: 254px;
  }

  .selected-num {
    padding: 0 8px 8px $leftRightPadding;
  }
  .selected-chips {
    padding: 8px $leftRightPadding;

    .no-programs-selected {
      color: $light-grey-text1;
    }
  }
}

.save-btn-container {
  padding: 16px $leftRightPadding;
  border-top: $mui-grey-border;
  position: static;
  @include borderBoxMixin;

  div {
    @include borderBoxMixin;
  }
}

.selected-chips-dialog-mobile {
  div {
    @include borderBoxMixin;
  }
  h4 {
    font-size: rem(18);
    position: relative;
    top: 5px;
    margin-left: 0;
  }
}

@media screen and(max-width: 1135px) {
  .loyalty-selection-poc {
    .search-and-programs {
      width: $col-3;
    }
  }
}

// @media screen and(max-width: 999px) {
//   .loyalty-selection-poc {
//     .loyalty-programs-list-container {
//       // height: $mobile-view-height;
//       max-height: initial;
//       min-height: initial;
//     }
//   }
// }

@media screen and(max-width: 880px) {
  .loyalty-selection-poc {
    .search-and-programs {
      width: $col-2-dsktp;
    }
    .loyalty-programs-list-container {
      height: $tablet-view-height;
    }
  }
}

@media screen and(max-width: 715px) {
  .loyalty-selection-poc {
    .flex-content {
      display: block;
      width: auto;
    }

    .search-and-programs {
      border-right: none;
      margin: 0 auto;
      width: $col-3;

      .search-wrapper {
        padding: 6px 24px 0;
      }
    }

    .loyalty-programs-list-container {
      height: $mobile-view-height;
    }

    .aside-section {
      flex: none;
      position: absolute;
      min-width: auto;
      width: 100%;
      bottom: $bottom-offset-mobile + $button-offset-mobile;
      right: 0;
      left: 0;
      max-width: 400px;
      margin: 0 auto;
      .award-wallet-section {
        div.award-wallet-btn-containers {
          padding: 0;
          display: flex;
          justify-content: flex-end;
        }

        button.award-wallet-btn {
          display: none;
        }

        h6 {
          display: none;
        }

        .selected-chips-btn-mobile {
          display: initial;
        }
      }
    }

    .selected-chips-container.dsktp {
      display: none;
    }
  }

  .award-wallet-btn-container-mobile {
    display: block;
  }

  .save-btn-container {
    position: absolute;
    bottom: $bottom-offset-mobile;
    width: 100%;
  }
}

@media screen and(max-width: 576px) {
  .loyalty-selection-poc {
    .loyalty-selection-dialog-title {
      padding: 16px;
      h4 {
        font-size: rem(23);
        text-align: center;
        margin-left: 0;
      }
      .subtitle h6 {
        font-size: rem(16);
      }
    }

    .search-and-programs {
      width: $col-2;

      .search-wrapper {
        padding: 6px 6px 0;
      }
    }

    .loyalty-programs-status-container {
      padding: 6px 0 0 0;
    }

    .aside-section {
      flex: none;
    }
  }
  .selected-chips-dialog-mobile {
    h4 {
      top: 3px;
      margin-left: $dialog-title-margin-left;
    }
  }
}

@media screen and (max-width: 400px) {
  .loyalty-selection-poc {
    .loyalty-selection-dialog-title {
      padding-right: 20px;
      h4 {
        text-align: right;
      }
    }
    .aside-section {
      width: initial;
      right: 8px;
    }
    .search-and-programs {
      width: 375px;
    }
  }

  .loyalty-programs-list-container {
    padding: 4px 0 0 0;

    .loyalty-programs-list-results {
      width: 332px;
      margin: 0 auto;
    }
  }

  .card-outline-container {
    margin: 4px 2px 4px 0;
  }
}

// very small phones
@media screen and (max-width: 366px) {
  .loyalty-selection-poc {
    .loyalty-selection-dialog-title h4 {
      font-size: rem(18);
    }

    .search-and-programs {
      width: 100%;
    }

    .loyalty-programs-list-container {
      height: $mobile-sm-view-height;

      .loyalty-programs-list-results {
        padding: 0 7px;
        width: initial;
      }
    }
  }
  .card-outline-container {
    & .airline-card,
    & .airline-card-outlined {
      width: 146px;
    }
  }
}

// desktop height
@media screen and (min-height: 900px) and (min-width: 900px) {
  .loyalty-selection-poc .loyalty-programs-list-container {
    height: $desktop-view-height;
  }
  .card-outline-container {
    .airline-card {
      &:hover {
        border: $border solid #e4e4e4;
        margin: -$border 0 0 0;
      }
    }
  }
}
