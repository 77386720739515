// ---------------
// FONTS
// ---------------

// Cabin:
$second-font-url: 'https://fonts.googleapis.com/css?family=Cabin:400,400i,700,700i';
$main-font-family: 'Rubik';
$second-font-family: 'Cabin', Verdana, 'Open Sans', sans-serif;

// Lato:
// https://fonts.googleapis.com/css?family=Lato:400,700,400italic,700italic&subset=latin
// 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;

// FONT SIZING

$base-font-size: 16;

// @example
// body {
//   font-size: rem(20) -> 1.25rem
// }
@function rem($pixel) {
  // the '+' just concatenates the division with 'rem'
  @return ($pixel/$base-font-size) + rem;
}

// Main Search Form
$main-search-form-font-size: 15px;

// flight search font
$flight-search-font-weight: 500;

// ------------------------
// COLORS
// ------------------------

// flight search input color
$maroon-input-color: #44284f;

// flight search box gradients
$blue-bottom-search-input: #5ca9ff;
$blue-top-search-input: #5db0ff;
$blue-top-searchbox: #0194ff;

// flight search button
$treklee-light-blue1: #00bbff;
$treklee-med-blue1: #0191ff;
$treklee-dark-blue1: #004cff;
$treklee-dark-blue2: #004bff;

$date-input-border: #5399df;

// buttons inside signup dialog form
$signup-with-google: #3d81f6;
$signup-with-facebook: #405bb0;

$light-grey-border: #dcdcdc;
$placeholder-color: #bbb;
$separator-color: #b9b9b9;

$error1: #ea4335; // close to pure red, borrowed from google
$error2: #b01919;
$error3: #ff2300;
$error4: #ff5d5d;

// calendar

$cal-selected-date: $treklee-dark-blue2;
// $cal-non-selected-date: rgb(0, 187, 255);
$cal-non-selected-date: $treklee-light-blue1;
$cal-hovered-date: lighten($cal-non-selected-date, 35%);
$cal-picker-shadow: grey;

$light-grey-text1: #808080;
$light-grey-text2: #4e4e4e;

// material ui
$mui-grey-border: 1px solid rgba(0, 0, 0, 0.12);

// icons
// $cal-icon: $treklee-dark-blue2; // using svg background

// --------------------------
// UTILITIES
// --------------------------

$max-page-width: 1050px;

$match-border-radius: 4.5px;

@mixin borderBoxMixin {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}
