@media screen and (max-width: 899px) {
  .airport-autocomplete.desktop {
    height: 40px;

    &.to .results {
      left: auto;
      right: -10px;
    }
  }
}
