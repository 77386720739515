.login-container,
.signup-container {
  margin: 0 auto;
  max-width: 400px;
  padding: 12px;
  text-align: center;
  h1,
  h2 {
    margin-bottom: 22px;
    padding: 0 12px;
  }
  h1 {
    font-size: 30px;
  }
  h2 {
    font-size: 14px;
    color: #808080;
  }
}

.login-container {
  button.login-forgot-password {
    align-items: center;
    color: #808080;
    display: inline-flex;
    font-size: rem(14);
    padding: 12px 6px;
    vertical-align: middle;
  }
  .hr-with-text {
    position: relative;
    top: 16px;
  }
  & > hr {
    margin: 12px 0 4px 0;
  }
}

.signup-container {
  button.btn-dialog-email-signup-submit {
    margin-top: 32px;
  }
  .hr-with-text {
    margin-top: 16px;
  }
}

button.btn-dialog-full-width {
  color: white;
  font-size: 16px;
  margin: 4px 0;
  width: 100%;
}

button.btn-dialog-email-cancel {
  color: #808080;
}

// #00bbff
button.btn-dialog-email-signup {
  background-color: $treklee-light-blue1;
  &:hover {
    background-color: darken($treklee-light-blue1, 5%);
  }
}

button.btn-dialog-google-signup {
  background-color: $signup-with-google;
  &:hover {
    background-color: darken($signup-with-google, 5%);
  }

  img {
    border-radius: 1px;
    height: 28px;
    position: absolute;
    left: 0%;
    margin-left: 4px;
  }
}

button.btn-dialog-facebook-signup {
  background-color: $signup-with-facebook;
  &:hover {
    background-color: darken($signup-with-facebook, 8%);
  }

  img {
    border-radius: 1px;
    height: 24px;
    position: absolute;
    left: 0%;
    margin-left: 6px;
  }
}

button.btn-dialog-plain {
  color: $treklee-light-blue1;
  // &:hover {
  //   background-color: rgba(0, 0, 0, 0.08);
  // }
}

div hr.hr-dialog-signup {
  margin: 14px auto;
  width: 70%;
  border-color: $light-grey-border;
}

.hr-lite {
  height: 1px;
  border: 0;
  border-top: 1px solid $light-grey-border;
}

.hr-with-text {
  height: 16px;
  span {
    background: white;
    color: #737373;
    display: block;
    font-size: 14px;
    margin: 0 auto;
    position: relative;
    top: -20px;
    width: 48px;
  }
}
