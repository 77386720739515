.mobile-device {
  .header-container {
    height: 56px;

    .account-menu-desktop {
      display: none;
    }

    .account-menu-mobile {
      display: initial;
    }

    .logo-link {
      // display: none;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0%);
    }

    .toolbar {
      min-height: 56px;
    }
  }
}

.header-container {
  height: 64px;

  .toolbar {
    max-width: $max-page-width; // prevent content from being too far left and right
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
    justify-content: space-between;
  }

  .account-menu-mobile {
    display: none;
  }

  .navbar-appbar {
    background-color: #fff;
    color: #333;
    display: block;

    .logo-link {
      img {
        width: 140px;
      }
    }

    .mobile-menu-icon-btn {
      // ignore toolbar padding
      margin-left: -20px;
    }
  }
}

ul.account-menu-list {
  margin: 8px 0 0 0;
  padding: 0;
  list-style: none;

  .nav-menu-text {
    padding-left: 16px;

    &.nav-item-padded {
      padding-left: 30px;
    }
  }

  li {
    padding-top: 4px;
    padding-bottom: 4px;
    min-height: auto;
  }

  a {
    color: inherit;
    text-decoration: none;
    width: 100%;
  }
}

// child of body
.account-menu-common {
  .hr-bott {
    margin-top: 6px;
  }

  // .nav-item-heading {
  //   font-weight: bold;
  // }

  // .nav-item-muted {
  //   color: #808080;
  // }

  .nav-item-text {
    padding-left: 16px;

    &.nav-item-padded {
      padding-left: 30px;
    }
  }
}

// child of body
.account-menu-mobile-drawer {
  min-width: 250px;

  .menu-logo-container {
    padding-top: 16px;
    padding-bottom: 16px;

    a {
      display: inline-block;
    }

    img {
      height: 21px;
    }
  }
}

.account-menu-desktop {
  // padding-right: 12px;

  // not logged in
  .account-menu-desktop-guest-btn {
    text-transform: uppercase;
  }

  // logged in
  // this is separate from .user-profile-dropdown-content
  .user-profile-dropdown {
    button {
      width: 44px;
      height: 44px;
      color: #9e9e9e;
      background: white;
      box-shadow: none;

      svg {
        font-size: rem(44);
      }
    }
  }
}

.user-profile-dropdown-content {
  width: 225px;

  ul.user-profile-info {
    padding: 0 0 16px 16px;
  }
}

@media screen and (max-width: 768px) {
  .header-container {
    height: 56px;

    .navbar-appbar {
      .logo-link {
        // display: none;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0%);
      }
    }
  }
}
