.back-button-mobile {
  cursor: pointer;
  // display: block;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  span {
    // line-height: 55px;
    color: #b0b0b0;
    font-style: normal;
  }
}
